import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useStore } from "effector-react";
import { ClickAwayListener } from "@mui/material";

import { DowntownLogo } from "shared/ui/downtown-logo";
import { ModalLinkButton } from "shared/ui/button";
import { mediaPhone } from "shared/ui/styles/media";
import { useOpenableState } from "shared/lib/use-openable-state";
import { Avatar } from "shared/ui/avatar/avatar";
import { ArrowIcon } from "shared/ui/icons";
import {
  NavigationMenuIcon,
  NavigationMenuItem,
} from "shared/ui/templates/authorized-page-template";
import { ReactComponent as LogoutIcon } from "shared/ui/templates/authorized-page-template/logout.svg";

import { $viewer } from "@client-app/entities/viewer";

export interface Props {
  navigation: ReactNode;
  onSignOutClick(): void;
  className?: string;
  isHero?: boolean;
}

export function DesktopHeader(props: Props): JSX.Element {
  const user = useStore($viewer);

  const menu = useOpenableState();
  const userName = user ? [user.firstName, user.lastName].join(" ") : "";

  return (
    <HeaderRoot className={props.className} isHero={props.isHero}>
      <HeaderContent>
        <HomePageLink to="/">
          <DowntownLogo $dark={!props.isHero} />
        </HomePageLink>
        <HeaderActions>
          {user ? (
            <>
              <MenuButton onClick={menu.toggle} isOpened={menu.isOpened}>
                <Avatar name={userName} />
                <MenuIcon as={ArrowIcon}></MenuIcon>
              </MenuButton>
              {menu.isOpened && (
                <ClickAwayListener onClickAway={menu.close}>
                  <Menu>
                    <MenuContent>
                      <UserInfo>
                        <Avatar name={userName} />
                        <div>
                          <UserName>{userName}</UserName>
                          <UserEmail>{user.email}</UserEmail>
                        </div>
                      </UserInfo>

                      <Navigation>{props.navigation}</Navigation>
                    </MenuContent>

                    <MenuSplitter />
                    <MenuContent>
                      <LogoutBlock>
                        <NavigationMenuItem onClick={props.onSignOutClick}>
                          <NavigationMenuIcon as={LogoutIcon} /> Logout
                        </NavigationMenuItem>
                      </LogoutBlock>
                    </MenuContent>
                  </Menu>
                </ClickAwayListener>
              )}
            </>
          ) : (
            <SignInButton modal="login" variant="outlined">
              Sign In / Sign Up
            </SignInButton>
          )}
        </HeaderActions>
      </HeaderContent>
    </HeaderRoot>
  );
}

const SignInButton = styled(ModalLinkButton)``;

const LogoutBlock = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  font-size: 20px;
  line-height: 26px;
`;

const UserEmail = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #5e616c;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 500;
  ${Avatar} {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-right: 16px;
  }
`;

const MenuIcon = styled.div`
  width: 16px;
  height: 16px;
`;

const MenuButton = styled.div<{ isOpened: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 3px 15px 3px 3px;
  border: 1px solid transparent;
  border-radius: 600px;

  ${Avatar} {
    margin-right: 16px;
  }

  ${MenuIcon} {
    transform: rotate(-90deg);
  }

  ${(props) =>
    props.isOpened &&
    css`
      background: #f5fafa;
      border-color: #e7ebf7;

      ${MenuIcon} {
        transform: rotate(90deg);
      }
    `}
`;

const MenuContent = styled.div`
  padding: 24px;
`;

const Navigation = styled.div``;

const MenuSplitter = styled.div`
  height: 1px;
  background: #e7ebf7;
`;

const Menu = styled.div`
  width: 320px;
  background: #fff;
  box-shadow: 0 0 20px rgba(139, 146, 153, 0.14);
  border-radius: 24px;

  ${Avatar} {
    width: 40px;
    height: 40px;
  }

  ${NavigationMenuItem}:not(.active) {
    color: #2d4fa1;
  }

  ${UserInfo} {
    margin-bottom: 40px;
  }

  ${MenuSplitter} {
    margin: 16px 0;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const HeaderContent = styled.div`
  width: var(--contentWidth);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  ${Menu} {
    position: absolute;
    right: 0;
    top: 100%;
  }

  ${mediaPhone} {
    padding: 0 16px;
  }
`;

const HomePageLink = styled(Link)`
  display: flex;
`;

const HeaderRoot = styled.div`
  height: 88px;
  width: 100vw;
  max-width: 100%;
  background: #fbfcff;
  border-bottom: 1px solid #e7ebf7;
  display: flex;
  ${DowntownLogo} {
    width: 245px;
    height: 58px;
  }
  ${(props) =>
    props.isHero &&
    css`
      background: transparent;
      border: none;

      ${SignInButton} {
        color: #fbfcff;
        border-color: #fbfcff;
      }

      ${DowntownLogo} {
        width: 245px;
        height: 76px;
      }
    `}
`;
