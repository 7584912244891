import { useEvent, useGate, useStore } from "effector-react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Button } from "shared/ui/button";
import {
  createBreakpoint,
  createMedia,
  createMediaMaxWidth,
  mediaPhone,
  useBreakPoint,
} from "shared/ui/styles/media";
import { Footer } from "@client-app/shared/ui/footer";
import { CityCard } from "@client-app/entities/docks/ui/city-card";
import { Header } from "@client-app/widgets/header";
import { MetaTags } from "shared/lib/metaTags";

import heroImage from "./img/main-image.jpeg";
import heroImageMobile from "./img/main-image.mobile.jpeg";
import aboutUs from "./img/about-us.jpg";
import line from "./img/line.svg";
import {
  $cities,
  $page,
  $perPage,
  $totalCities,
  homePageGate,
  setPage,
  setPerPage,
} from "./model";

import { ReactComponent as AboutLine1Svg } from "./img/about-line-1.svg";
import { ReactComponent as AboutLine2Svg } from "./img/about-line-2.svg";
import { ReactComponent as AboutLine1MobileSvg } from "./img/about-line-1.mobile.svg";
import { ReactComponent as AboutLineMobile2Svg } from "./img/about-line-2.mobile.svg";
import { ReactComponent as Waves1Svg } from "./img/waves1.svg";
import { ReactComponent as Waves2Svg } from "./img/waves2.svg";
import AboutBackImgSvg from "./img/about-image-back.svg";

export function HomePage(): JSX.Element {
  useGate(homePageGate);

  const cities = useStore($cities);
  const totalCities = useStore($totalCities);
  const page = useStore($page);
  const perPage = useStore($perPage);

  const changePage = useEvent(setPage);
  const changePerPage = useEvent(setPerPage);

  return (
    <Root>
      <MetaTags title="Home" />
      <Header isHero={true} />
      <HeroBlock>
        <HeroContent>
          <Title>Local Docking Solutions Made Simple</Title>
          <SubTitle>
            Book your favorite dock or explore new ones near you!
          </SubTitle>

          <HeroButtons>
            <Link to="/dock-list">
              <Button>Book now</Button>
            </Link>

            <a href="#choose-your-city">
              <Button>Explore our cities</Button>
            </a>
          </HeroButtons>
        </HeroContent>
      </HeroBlock>
      <AboutUsBlock>
        <Waves1 />
        <BlockTitle>How It Works</BlockTitle>
        <Steps>
          <Step1>
            <StepTitle>Step 1</StepTitle>
            Create an account and add your boat.
          </Step1>

          <Step2>
            <AboutLine1 />
            <StepTitle>Step 2</StepTitle>
            View availability for all locations and select your date and time.
          </Step2>
          <Step3>
            <AboutLine2 />
            <StepTitle>Step 3</StepTitle>
            Create and manage your reservations through your profile.
          </Step3>
        </Steps>
        <AboutUsContent>
          <AboutUsImage>
            <AboutBackImg />
            <AboutUs src={aboutUs} />
          </AboutUsImage>

          <AboutUsMessage>
            <p>
              Here, you are surrounded by the finest experiences your city has
              to offer. From top-notch entertainment to exquisite dining, your
              adventure begins just steps away from your vessel.
            </p>
            <p>
              Our crew members at Downtown Docks have the expertise to assist
              you or your captain with docking and securing your vessel. Rest
              assured, your vessel will be safe and secure while moored at
              Downtown Docks.
            </p>
            <p>
              This is the premier place to dock in the city. Located centrally,
              you can enjoy the area from the privacy and comfort of your own
              vessel.
            </p>
          </AboutUsMessage>
        </AboutUsContent>
      </AboutUsBlock>

      <div id="choose-your-city">
        <DocksBlock>
          <Waves2 />
          <BlockTitle>Choose your city</BlockTitle>

          <Docks>
            {cities.map((city) => (
              <Link key={city.id!} to={`/cities/${city.id}`}>
                <CityCard city={city} />
              </Link>
            ))}
          </Docks>

          {totalCities && perPage * page < totalCities ? (
            <ViewMoreButton
              onClick={() =>
                perPage === 3 ? changePerPage(9) : changePage(page + 1)
              }
              variant="outlined"
            >
              View more
            </ViewMoreButton>
          ) : null}
        </DocksBlock>
      </div>

      <Footer />
    </Root>
  );
}

const breakPoint = createMediaMaxWidth(1200);

const Content = styled.div`
  width: 1180px;
  margin: 0 auto;
  position: relative;

  ${breakPoint} {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Title = styled.h1`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 54px;
  line-height: 66px;

  width: 100%;
  max-width: 587px;
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  width: 480px;
  ${mediaPhone} {
    width: auto;
    max-width: 508px;
  }
`;

const HeroContent = styled(Content)`
  padding-top: 164px;
  ${Title} {
    margin-bottom: 48px;
  }

  ${SubTitle} {
    margin-bottom: 48px;
  }
`;

const HeroBlock = styled.div`
  background: url(${heroImage}) no-repeat;
  background-size: cover;
  background-position: bottom right;
  height: 768px;

  color: #fbfcff;

  ${Button} {
    background-color: #3bb7b6;
    width: 180px;
  }

  a {
    text-decoration: none;
  }

  ${createMediaMaxWidth(768)} {
    background-image: url(${heroImageMobile});
  }
`;

const HeroButtons = styled.div`
  display: flex;
  gap: 20px;
`;

const BlockTitle = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: #0a1128;
  display: inline-block;

  :after {
    content: "";
    display: block;
    background: url(${line}) no-repeat;
    background-size: 100% 100%;
    height: 16px;
    width: 100%;
  }
`;

const Block = styled((props) => (
  <BlockRoot className={props.className}>
    <Content>{props.children}</Content>
  </BlockRoot>
))`
  text-align: center;
  padding-top: 64px;

  ${mediaPhone} {
    text-align: left;
  }
`;

const BlockRoot = styled.div``;

const StepTitle = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #0a1128;
`;

const Waves1 = styled(Waves1Svg)`
  width: 130px;
  height: 52px;
`;

const Waves2 = styled(Waves2Svg)`
  width: 64px;
  height: 23px;
`;

const stepsBlockBreakPoint = createBreakpoint({ width: 800, type: "max" });
const stepsPhoneMedia = createMedia(stepsBlockBreakPoint);

const AboutLine1 = styled((props) => {
  const isPhone = useBreakPoint(stepsBlockBreakPoint);
  return isPhone ? (
    <AboutLine1MobileSvg {...props} />
  ) : (
    <AboutLine1Svg {...props} />
  );
})``;

const AboutLine2 = styled((props) => {
  const isPhone = useBreakPoint(stepsBlockBreakPoint);
  return isPhone ? (
    <AboutLineMobile2Svg {...props} />
  ) : (
    <AboutLine2Svg {...props} />
  );
})``;

const Step = styled.div`
  position: relative;
  z-index: 1;
  width: 380px;
  background: #f1f3fa;
  border-radius: 24px;
  padding: 16px 24px 24px;
  text-align: left;

  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #5e616c;

  ${StepTitle} {
    margin-bottom: 8px;
  }
`;

const Step1 = styled(Step)``;
const Step2 = styled(Step)`
  ${AboutLine1} {
    width: 410px;
    height: 128px;
    position: absolute;
    right: 65%;
    bottom: 100%;
  }

  ${stepsPhoneMedia} {
    ${AboutLine1} {
      width: 75px;
      height: 64px;
      right: 50%;
    }
  }
`;
const Step3 = styled(Step)`
  ${AboutLine2} {
    width: 310px;
    height: 108px;
    position: absolute;
    left: 65%;
    bottom: 100%;
  }

  ${stepsPhoneMedia} {
    ${AboutLine2} {
      width: 55px;
      height: 72px;
      right: 50%;
    }
  }
`;

const Steps = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${Step1} {
    margin-bottom: 44px;
    left: 70px;
    z-index: 3;
  }
  ${Step2} {
    right: 70px;
    z-index: 2;

    margin-bottom: 48px;
    align-self: flex-end;
  }
  ${Step3} {
    left: 170px;
    z-index: 1;
  }

  ${stepsPhoneMedia} {
    ${Step1} {
      margin-bottom: 60px;
      left: 0;
    }
    ${Step2} {
      margin-bottom: 72px;
      right: 0;
    }

    ${Step3} {
      left: 16px;
    }
  }
`;

const AboutUsMessage = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0a1128;
  text-align: left;

  ${breakPoint} {
    padding: 0;
  }
`;

const AboutUs = styled.img`
  max-width: 520px;
  ${breakPoint} {
    max-width: 100%;
  }
  border-radius: 24px;
`;

const AboutBackImg = styled.div`
  width: 520px;
  height: 338px;

  background: url(${AboutBackImgSvg});
`;

const AboutUsImage = styled.div`
  position: relative;

  ${breakPoint} {
    max-width: calc(100vw - 32px);
    text-align: center;
  }

  ${AboutBackImg} {
    position: absolute;
    top: 60px;
    left: 80px;
  }

    ${breakPoint} {
      left: auto;
      right: -64px;
    }
  }

  ${AboutUs} {
    position: relative;
    z-index: 1;
  }
`;

const AboutUsContent = styled.div`
  width: 100%;

  display: flex;
  gap: 148px;

  ${AboutBackImg} {
  }

  ${breakPoint} {
    flex-direction: column-reverse;
    gap: 24px;
  }
`;

const AboutUsBlock = styled(Block)`
  padding-bottom: 80px;

  ${breakPoint} {
    overflow: hidden;
  }

  ${Waves1} {
    position: absolute;
    top: 64px;
    right: 80px;

    ${mediaPhone} {
      top: -31px;
      right: 30px;
    }
  }
`;

const ViewMoreButton = styled(Button)`
  width: 200px !important;
`;

const Docks = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  ${breakPoint} {
    grid-template-columns: 1fr;
  }
`;

const DocksBlock = styled(Block)`
  padding-bottom: 120px;

  a {
    text-decoration: none;
  }

  ${Waves2} {
    position: absolute;
    top: 0;
    left: 50px;

    ${mediaPhone} {
      left: auto;
      right: 24px;
      top: -30px;
    }
  }
`;

const Root = styled.div`
  --contentWidth: 1180px;

  ${mediaPhone} {
    --contentWidth: 100vw;
  }

  ${Header} {
    position: absolute;
    top: 0;
    z-index: 1;
  }

  ${Steps} {
    margin: 40px 0;
  }

  ${Docks} {
    margin-top: 40px;
  }

  ${ViewMoreButton} {
    display: block;
    margin: 30px auto 0;
  }
`;
