import { createEffect, createStore, sample } from "effector";
import { reset } from "patronum";

import {
  dockSelected,
  $selectedBoatURI,
  $duration,
  $date,
} from "@client-app/pages/dock-list/model";
import { history } from "shared/config/histroy";
import {
  setReservationInfo,
  reservationCreated,
  BaseReservationInfo,
} from "@client-app/features/reserv-dock";

import { Boat, Reservation } from "shared/api/types";

import {
  $reservation,
  loginAndConfirmBookingClicked,
  ReservationApiInput,
  tryToCreateReservationFx,
} from "../../features/reserv-dock/model/create-reservation";
import { login } from "../../entities/viewer";
import { createBoatFx } from "../../features/manage-boats/add/model";
import { $noAuthUserBoat } from "../../entities/boats";

export function init(): void {
  initBookingWithoutLoginProcess();

  const clientSelectDockForReservationFx = createEffect(
    (params: { dockId: number; reservationInfo: BaseReservationInfo }) => {
      setReservationInfo(params.reservationInfo);
      history.push(`/dock-list/${params.dockId}`);
    }
  );

  const reservationCreatedFx = createEffect((reservation: Reservation) => {
    history.push(`/reservations-list/${reservation.id}/pay/`);
  });

  sample({
    clock: dockSelected,
    source: {
      boatId: $selectedBoatURI,
      duration: $duration,
      date: $date,
    },
    fn: (reservationInfo, dockId) => ({ reservationInfo, dockId }),
    target: clientSelectDockForReservationFx,
  });

  sample({
    clock: reservationCreated,
    target: reservationCreatedFx,
  });
}

function initBookingWithoutLoginProcess() {
  const $isBookingWithoutLoinInProgress = createStore(false);
  const $savedReservation = createStore<ReservationApiInput>(null);
  const $savedBoat = $noAuthUserBoat;

  $isBookingWithoutLoinInProgress.on(loginAndConfirmBookingClicked, () => true);

  const createReservationAfterLogin = createEffect(
    async ({
      boat,
      reservation,
    }: {
      boat: Boat;
      reservation: ReservationApiInput;
    }) => {
      const createdBoat = await createBoatFx(boat);
      const boatIRI: string = createdBoat["@id"];
      await tryToCreateReservationFx({
        ...reservation,
        boat: boatIRI,
      });
    }
  );

  sample({
    clock: loginAndConfirmBookingClicked,
    source: $noAuthUserBoat,
    target: $savedBoat,
  });

  sample({
    // @ts-ignore
    clock: loginAndConfirmBookingClicked,
    source: $reservation,
    target: $savedReservation,
  });

  sample({
    // @ts-ignore
    clock: login,
    source: {
      boat: $savedBoat,
      reservation: $savedReservation,
    },
    filter: $isBookingWithoutLoinInProgress,
    target: createReservationAfterLogin,
  });

  reset({
    clock: [createReservationAfterLogin.done, createReservationAfterLogin.fail],
    target: [
      $noAuthUserBoat,
      $savedReservation,
      $isBookingWithoutLoinInProgress,
      $savedBoat,
    ],
  });
}
