//  0.0.0
// ---
// This file is automatically generated by openapi with preset openapi-preset-effector
// Do not edit this file directly. Instead open openapi config file and follow the link in "file"
import { createEffect } from "effector";
import * as typed from "typed-contracts";

import { requestFx } from "./request";

//#region prebuilt code

export type GenericErrors =
  | {
    status: "unexpected";
    error: Error;
  }
  | {
    status: "unknown_status";
    error: { status: number; body: unknown };
  }
  | {
    status: "validation_error";
    error: typed.ValidationError;
  };

type ErrorCodes =
  | 400
  | 401
  | 402
  | 403
  | 404
  | 405
  | 406
  | 422
  | 500
  | 501
  | 502
  | 503
  | 505;
/**
 * @throws
 */
function parseByStatus<
  Variants extends string,
  Contracts extends Record<number, [Variants, typed.Contract<any>]>,
  Result extends {
    [Code in keyof Contracts]: Contracts[Code] extends [
      infer Status,
      typed.Contract<infer T>
    ]
    ? { status: Status; answer: T }
    : never;
  }
>(
  name: string,
  response: { status: number; body?: unknown },
  contracts: Contracts
): Result[Exclude<keyof Result, ErrorCodes>] {
  const contractObject = contracts[response.status];
  if (!contractObject) {
    throw {
      status: "unknown_status",
      error: {
        status: response.status,
        body: response.body,
      },
    };
  }
  const [status, contract] = contractObject;
  const answer = contract(name, response.body);
  if (answer instanceof typed.ValidationError) {
    throw { status: "validation_error", error: answer };
  }
  if (response.status >= 400) {
    throw { status, error: answer };
  }
  return { status, answer } as Result[Exclude<keyof Result, ErrorCodes>];
}

//#endregion prebuilt code/* --- */
//#region postAdminForgotPassword
export type PostAdminForgotPassword = {
  body?: {
    email: string;
  };
};

/* Email with reset token sent */
export const postAdminForgotPasswordNoContent = typed.nul;
export type PostAdminForgotPasswordDone = {
  status: "no_content";
  answer: typed.Get<typeof postAdminForgotPasswordNoContent>;
};

/* Invalid input */
export const postAdminForgotPasswordBadRequest = typed.nul;

/* Unprocessable entity */
export const postAdminForgotPasswordUnprocessableEntity = typed.nul;
export type PostAdminForgotPasswordFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postAdminForgotPasswordBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postAdminForgotPasswordUnprocessableEntity>;
  }
  | GenericErrors;
export const postAdminForgotPassword = createEffect<
  PostAdminForgotPassword,
  PostAdminForgotPasswordDone,
  PostAdminForgotPasswordFail
>({
  async handler({ body }) {
    const name = "postAdminForgotPassword.body";
    const response = await requestFx({
      path: "/admin/forgot-password",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      204: ["no_content", postAdminForgotPasswordNoContent],
      400: ["bad_request", postAdminForgotPasswordBadRequest],
      422: ["unprocessable_entity", postAdminForgotPasswordUnprocessableEntity],
    });
  },
});
//#endregion postAdminForgotPassword

/* --- */
//#region getAdminForgotPassword
export type GetAdminForgotPassword = {
  path: {
    token: string;
  };
};

/* Valid token */
export const getAdminForgotPasswordOk = typed.nul;
export type GetAdminForgotPasswordDone = {
  status: "ok";
  answer: typed.Get<typeof getAdminForgotPasswordOk>;
};

/* Incorrect or confirmed token */
export const getAdminForgotPasswordNotFound = typed.nul;
export type GetAdminForgotPasswordFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getAdminForgotPasswordNotFound>;
  }
  | GenericErrors;
export const getAdminForgotPassword = createEffect<
  GetAdminForgotPassword,
  GetAdminForgotPasswordDone,
  GetAdminForgotPasswordFail
>({
  async handler({ path }) {
    const name = "getAdminForgotPassword.body";
    const response = await requestFx({
      path: `/admin/forgot-password/${path.token}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getAdminForgotPasswordOk],
      404: ["not_found", getAdminForgotPasswordNotFound],
    });
  },
});
//#endregion getAdminForgotPassword

/* --- */
//#region postAdminForgotPasswordToken
export type PostAdminForgotPasswordToken = {
  body?: {
    password: string;
  };
  path: {
    token: string;
  };
};

/* Password changed */
export const postAdminForgotPasswordTokenNoContent = typed.nul;
export type PostAdminForgotPasswordTokenDone = {
  status: "no_content";
  answer: typed.Get<typeof postAdminForgotPasswordTokenNoContent>;
};

/* Invalid input */
export const postAdminForgotPasswordTokenBadRequest = typed.nul;

/* Incorrect or confirmed token */
export const postAdminForgotPasswordTokenNotFound = typed.nul;

/* Unprocessable entity */
export const postAdminForgotPasswordTokenUnprocessableEntity = typed.nul;
export type PostAdminForgotPasswordTokenFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postAdminForgotPasswordTokenBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof postAdminForgotPasswordTokenNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postAdminForgotPasswordTokenUnprocessableEntity>;
  }
  | GenericErrors;
export const postAdminForgotPasswordToken = createEffect<
  PostAdminForgotPasswordToken,
  PostAdminForgotPasswordTokenDone,
  PostAdminForgotPasswordTokenFail
>({
  async handler({ body, path }) {
    const name = "postAdminForgotPasswordToken.body";
    const response = await requestFx({
      path: `/admin/forgot-password/${path.token}`,
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      204: ["no_content", postAdminForgotPasswordTokenNoContent],
      400: ["bad_request", postAdminForgotPasswordTokenBadRequest],
      404: ["not_found", postAdminForgotPasswordTokenNotFound],
      422: [
        "unprocessable_entity",
        postAdminForgotPasswordTokenUnprocessableEntity,
      ],
    });
  },
});
//#endregion postAdminForgotPasswordToken

/* --- */
//#region postAdminLogin
export type PostAdminLogin = {
  body?: {
    email?: string;
    password?: string;
  };
};

/* User authorized. Refresh page. */
export const postAdminLoginNoContent = typed.nul;
export type PostAdminLoginDone = {
  status: "no_content";
  answer: typed.Get<typeof postAdminLoginNoContent>;
};
export type PostAdminLoginFail = GenericErrors;
export const postAdminLogin = createEffect<
  PostAdminLogin,
  PostAdminLoginDone,
  PostAdminLoginFail
>({
  async handler({ body }) {
    const name = "postAdminLogin.body";
    const response = await requestFx({
      path: "/admin/login",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      204: ["no_content", postAdminLoginNoContent],
    });
  },
});
//#endregion postAdminLogin

/* --- */
//#region postAdminLogout
export type PostAdminLogout = {};

/* Session invalidated. Refresh page. */
export const postAdminLogoutNoContent = typed.nul;
export type PostAdminLogoutDone = {
  status: "no_content";
  answer: typed.Get<typeof postAdminLogoutNoContent>;
};
export type PostAdminLogoutFail = GenericErrors;
export const postAdminLogout = createEffect<
  PostAdminLogout,
  PostAdminLogoutDone,
  PostAdminLogoutFail
>({
  async handler() {
    const name = "postAdminLogout.body";
    const response = await requestFx({
      path: "/admin/logout",
      method: "POST",
    });
    return parseByStatus(name, response, {
      204: ["no_content", postAdminLogoutNoContent],
    });
  },
});
//#endregion postAdminLogout

/* --- */
//#region getAdminMe
export type GetAdminMe = {};

/* A JSON object containing current user information. */
export const getAdminMeOk = typed.union(
  typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    email: typed.string,
    firstName: typed.string,
    createdAt: typed.string.optional,
    updatedAt: typed.string.maybe,
  }),
  typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    email: typed.string,
    company: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
    }).optional,
    firstName: typed.string,
    lastName: typed.string,
    phone: typed.string.maybe,
    createdAt: typed.string.optional,
    updatedAt: typed.string.maybe,
  }),
  typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    email: typed.string,
    firstName: typed.string,
    lastName: typed.string,
    phone: typed.string,
    createdAt: typed.string.optional,
    updatedAt: typed.string.maybe,
    spentMoney: typed.string.optional,
    address: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      address1: typed.string.maybe,
      address2: typed.string.maybe,
      city: typed.string.maybe,
      state: typed.string.maybe,
      zipCode: typed.string.maybe,
    }).optional,
    comment: typed.string.maybe,
  })
);
export type GetAdminMeDone = {
  status: "ok";
  answer: typed.Get<typeof getAdminMeOk>;
};
export type GetAdminMeFail = GenericErrors;
export const getAdminMe = createEffect<
  GetAdminMe,
  GetAdminMeDone,
  GetAdminMeFail
>({
  async handler() {
    const name = "getAdminMe.body";
    const response = await requestFx({
      path: "/admin/me",
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getAdminMeOk],
    });
  },
});
//#endregion getAdminMe

/* --- */
//#region getAdminCollection
export type GetAdminCollection = {
  query?: {
    /* The collection page number */
    page?: number;

    /* The number of items per page */
    itemsPerPage?: number;

    /* Enable or disable pagination */
    pagination?: boolean;
  };
};

/* Admin collection */
export const getAdminCollectionOk = typed.object({
  "hydra:member": typed.array(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      createdAt: typed.string.optional,
      updatedAt: typed.string.maybe,
    })
  ),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional,
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(
      typed.object({
        "@type": typed.string.optional,
        variable: typed.string.optional,
        property: typed.string.maybe,
        required: typed.boolean.optional,
      })
    ).optional,
  }).optional,
});
export type GetAdminCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getAdminCollectionOk>;
};
export type GetAdminCollectionFail = GenericErrors;

/* Retrieves the collection of Admin resources. */
export const getAdminCollection = createEffect<
  GetAdminCollection,
  GetAdminCollectionDone,
  GetAdminCollectionFail
>({
  async handler({ query }) {
    const name = "getAdminCollection.body";
    const response = await requestFx({
      path: "/api/admins",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getAdminCollectionOk],
    });
  },
});
//#endregion getAdminCollection

/* --- */
//#region postAdminCollection
export type PostAdminCollection = {
  body: {
    email: string;
    password?: string;
    firstName: string;
  };
};

/* Admin resource created */
export const postAdminCollectionCreated = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  email: typed.string,
  firstName: typed.string,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
});
export type PostAdminCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postAdminCollectionCreated>;
};

/* Invalid input */
export const postAdminCollectionBadRequest = typed.nul;

/* Unprocessable entity */
export const postAdminCollectionUnprocessableEntity = typed.nul;
export type PostAdminCollectionFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postAdminCollectionBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postAdminCollectionUnprocessableEntity>;
  }
  | GenericErrors;

/* Creates a Admin resource. */
export const postAdminCollection = createEffect<
  PostAdminCollection,
  PostAdminCollectionDone,
  PostAdminCollectionFail
>({
  async handler({ body }) {
    const name = "postAdminCollection.body";
    const response = await requestFx({
      path: "/api/admins",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postAdminCollectionCreated],
      400: ["bad_request", postAdminCollectionBadRequest],
      422: ["unprocessable_entity", postAdminCollectionUnprocessableEntity],
    });
  },
});
//#endregion postAdminCollection

/* --- */
//#region getAdminItem
export type GetAdminItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Admin resource */
export const getAdminItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  email: typed.string,
  firstName: typed.string,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
});
export type GetAdminItemDone = {
  status: "ok";
  answer: typed.Get<typeof getAdminItemOk>;
};

/* Resource not found */
export const getAdminItemNotFound = typed.nul;
export type GetAdminItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getAdminItemNotFound>;
  }
  | GenericErrors;

/* Retrieves a Admin resource. */
export const getAdminItem = createEffect<
  GetAdminItem,
  GetAdminItemDone,
  GetAdminItemFail
>({
  async handler({ path }) {
    const name = "getAdminItem.body";
    const response = await requestFx({
      path: `/api/admins/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getAdminItemOk],
      404: ["not_found", getAdminItemNotFound],
    });
  },
});
//#endregion getAdminItem

/* --- */
//#region putAdminItem
export type PutAdminItem = {
  body: {
    email: string;
    password?: string;
    firstName: string;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Admin resource updated */
export const putAdminItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  email: typed.string,
  firstName: typed.string,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
});
export type PutAdminItemDone = {
  status: "ok";
  answer: typed.Get<typeof putAdminItemOk>;
};

/* Invalid input */
export const putAdminItemBadRequest = typed.nul;

/* Resource not found */
export const putAdminItemNotFound = typed.nul;

/* Unprocessable entity */
export const putAdminItemUnprocessableEntity = typed.nul;
export type PutAdminItemFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof putAdminItemBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof putAdminItemNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof putAdminItemUnprocessableEntity>;
  }
  | GenericErrors;

/* Replaces the Admin resource. */
export const putAdminItem = createEffect<
  PutAdminItem,
  PutAdminItemDone,
  PutAdminItemFail
>({
  async handler({ body, path }) {
    const name = "putAdminItem.body";
    const response = await requestFx({
      path: `/api/admins/${path.id}`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putAdminItemOk],
      400: ["bad_request", putAdminItemBadRequest],
      404: ["not_found", putAdminItemNotFound],
      422: ["unprocessable_entity", putAdminItemUnprocessableEntity],
    });
  },
});
//#endregion putAdminItem

/* --- */
//#region deleteAdminItem
export type DeleteAdminItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Admin resource deleted */
export const deleteAdminItemNoContent = typed.nul;
export type DeleteAdminItemDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteAdminItemNoContent>;
};

/* Resource not found */
export const deleteAdminItemNotFound = typed.nul;
export type DeleteAdminItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof deleteAdminItemNotFound>;
  }
  | GenericErrors;

/* Removes the Admin resource. */
export const deleteAdminItem = createEffect<
  DeleteAdminItem,
  DeleteAdminItemDone,
  DeleteAdminItemFail
>({
  async handler({ path }) {
    const name = "deleteAdminItem.body";
    const response = await requestFx({
      path: `/api/admins/${path.id}`,
      method: "DELETE",
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteAdminItemNoContent],
      404: ["not_found", deleteAdminItemNotFound],
    });
  },
});
//#endregion deleteAdminItem

/* --- */
//#region getBoatCollection
export type GetBoatCollection = {
  query?: {
    /* The collection page number */
    page?: number;

    /* The number of items per page */
    itemsPerPage?: number;

    /* Enable or disable pagination */
    pagination?: boolean;
    client?: string;
    "client[]"?: string[];
  };
};

/* Boat collection */
export const getBoatCollectionOk = typed.object({
  "hydra:member": typed.array(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      manufacturer: typed.string,
      model: typed.string,
      year: typed.number,
      loa: typed.number,
      beam: typed.number,
      draft: typed.number.maybe,
      height: typed.number.maybe,
      isMain: typed.boolean.optional,
      createdAt: typed.string.optional,
      updatedAt: typed.string.optional,
    })
  ),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional,
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(
      typed.object({
        "@type": typed.string.optional,
        variable: typed.string.optional,
        property: typed.string.maybe,
        required: typed.boolean.optional,
      })
    ).optional,
  }).optional,
});
export type GetBoatCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getBoatCollectionOk>;
};
export type GetBoatCollectionFail = GenericErrors;

/* Retrieves the collection of Boat resources. */
export const getBoatCollection = createEffect<
  GetBoatCollection,
  GetBoatCollectionDone,
  GetBoatCollectionFail
>({
  async handler({ query }) {
    const name = "getBoatCollection.body";
    const response = await requestFx({
      path: "/api/boats",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getBoatCollectionOk],
    });
  },
});
//#endregion getBoatCollection

/* --- */
//#region postBoatCollection
export type PostBoatCollection = {
  body: {
    name: string;
    manufacturer: string;
    model: string;
    year: number;
    loa: number;
    beam: number;
    draft?: number | null;
    height?: number | null;
    isMain?: boolean;
  };
};

/* Boat resource created */
export const postBoatCollectionCreated = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
  manufacturer: typed.string,
  model: typed.string,
  year: typed.number,
  loa: typed.number,
  beam: typed.number,
  draft: typed.number.maybe,
  height: typed.number.maybe,
  isMain: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
});
export type PostBoatCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postBoatCollectionCreated>;
};

/* Invalid input */
export const postBoatCollectionBadRequest = typed.nul;

/* Unprocessable entity */
export const postBoatCollectionUnprocessableEntity = typed.nul;
export type PostBoatCollectionFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postBoatCollectionBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postBoatCollectionUnprocessableEntity>;
  }
  | GenericErrors;

/* Creates a Boat resource. */
export const postBoatCollection = createEffect<
  PostBoatCollection,
  PostBoatCollectionDone,
  PostBoatCollectionFail
>({
  async handler({ body }) {
    const name = "postBoatCollection.body";
    const response = await requestFx({
      path: "/api/boats",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postBoatCollectionCreated],
      400: ["bad_request", postBoatCollectionBadRequest],
      422: ["unprocessable_entity", postBoatCollectionUnprocessableEntity],
    });
  },
});
//#endregion postBoatCollection

/* --- */
//#region getBoatItem
export type GetBoatItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Boat resource */
export const getBoatItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
  manufacturer: typed.string,
  model: typed.string,
  year: typed.number,
  loa: typed.number,
  beam: typed.number,
  draft: typed.number.maybe,
  height: typed.number.maybe,
  isMain: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
});
export type GetBoatItemDone = {
  status: "ok";
  answer: typed.Get<typeof getBoatItemOk>;
};

/* Resource not found */
export const getBoatItemNotFound = typed.nul;
export type GetBoatItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getBoatItemNotFound>;
  }
  | GenericErrors;

/* Retrieves a Boat resource. */
export const getBoatItem = createEffect<
  GetBoatItem,
  GetBoatItemDone,
  GetBoatItemFail
>({
  async handler({ path }) {
    const name = "getBoatItem.body";
    const response = await requestFx({
      path: `/api/boats/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getBoatItemOk],
      404: ["not_found", getBoatItemNotFound],
    });
  },
});
//#endregion getBoatItem

/* --- */
//#region putBoatItem
export type PutBoatItem = {
  body: {
    name: string;
    manufacturer: string;
    model: string;
    year: number;
    loa: number;
    beam: number;
    draft?: number | null;
    height?: number | null;
    isMain?: boolean;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Boat resource updated */
export const putBoatItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
  manufacturer: typed.string,
  model: typed.string,
  year: typed.number,
  loa: typed.number,
  beam: typed.number,
  draft: typed.number.maybe,
  height: typed.number.maybe,
  isMain: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
});
export type PutBoatItemDone = {
  status: "ok";
  answer: typed.Get<typeof putBoatItemOk>;
};

/* Invalid input */
export const putBoatItemBadRequest = typed.nul;

/* Resource not found */
export const putBoatItemNotFound = typed.nul;

/* Unprocessable entity */
export const putBoatItemUnprocessableEntity = typed.nul;
export type PutBoatItemFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof putBoatItemBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof putBoatItemNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof putBoatItemUnprocessableEntity>;
  }
  | GenericErrors;

/* Replaces the Boat resource. */
export const putBoatItem = createEffect<
  PutBoatItem,
  PutBoatItemDone,
  PutBoatItemFail
>({
  async handler({ body, path }) {
    const name = "putBoatItem.body";
    const response = await requestFx({
      path: `/api/boats/${path.id}`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putBoatItemOk],
      400: ["bad_request", putBoatItemBadRequest],
      404: ["not_found", putBoatItemNotFound],
      422: ["unprocessable_entity", putBoatItemUnprocessableEntity],
    });
  },
});
//#endregion putBoatItem

/* --- */
//#region deleteBoatItem
export type DeleteBoatItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Boat resource deleted */
export const deleteBoatItemNoContent = typed.nul;
export type DeleteBoatItemDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteBoatItemNoContent>;
};

/* Resource not found */
export const deleteBoatItemNotFound = typed.nul;
export type DeleteBoatItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof deleteBoatItemNotFound>;
  }
  | GenericErrors;

/* Removes the Boat resource. */
export const deleteBoatItem = createEffect<
  DeleteBoatItem,
  DeleteBoatItemDone,
  DeleteBoatItemFail
>({
  async handler({ path }) {
    const name = "deleteBoatItem.body";
    const response = await requestFx({
      path: `/api/boats/${path.id}`,
      method: "DELETE",
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteBoatItemNoContent],
      404: ["not_found", deleteBoatItemNotFound],
    });
  },
});
//#endregion deleteBoatItem

/* --- */
//#region getCityCollection
export type GetCityCollection = {
  query?: {
    /* The collection page number */
    page?: number;

    /* The number of items per page */
    itemsPerPage?: number;

    /* Enable or disable pagination */
    pagination?: boolean;
  };
};

/* City collection */
export const getCityCollectionOk = typed.object({
  "hydra:member": typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
    state: typed.string,
    description: typed.string.maybe,
    heroImage: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    }),
    cardImage: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    })
  })),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(typed.object({
      "@type": typed.string.optional,
      variable: typed.string.optional,
      property: typed.string.maybe,
      required: typed.boolean.optional
    })).optional
  }).optional
});
export type GetCityCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getCityCollectionOk>;
};
export type GetCityCollectionFail = GenericErrors;

/* Retrieves the collection of City resources. */
export const getCityCollection = createEffect<GetCityCollection, GetCityCollectionDone, GetCityCollectionFail>({
  async handler({
    query
  }) {
    const name = "getCityCollection.body";
    const response = await requestFx({
      path: "/api/cities",
      method: "GET",
      query
    });
    return parseByStatus(name, response, {
      200: ["ok", getCityCollectionOk]
    });
  }

});
//#endregion getCityCollection

/* --- */
//#region postCityCollection
export type PostCityCollection = {
  body: {
    name: string;
    state: string;
    description?: string | null;
    heroImage: string;
    cardImage: string;
  };
};

/* City resource created */
export const postCityCollectionCreated = typed.object({
  "@context": typed.union(typed.string, typed.object({
    "@vocab": typed.string,
    hydra: typed.union("http://www.w3.org/ns/hydra/core#")
  })).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
  state: typed.string,
  description: typed.string.maybe,
  heroImage: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    url: typed.string.maybe
  }),
  cardImage: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    url: typed.string.maybe
  })
});
export type PostCityCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postCityCollectionCreated>;
};

/* Invalid input */
export const postCityCollectionBadRequest = typed.nul;

/* Unprocessable entity */
export const postCityCollectionUnprocessableEntity = typed.nul;
export type PostCityCollectionFail = {
  status: "bad_request";
  error: typed.Get<typeof postCityCollectionBadRequest>;
} | {
  status: "unprocessable_entity";
  error: typed.Get<typeof postCityCollectionUnprocessableEntity>;
} | GenericErrors;

/* Creates a City resource. */
export const postCityCollection = createEffect<PostCityCollection, PostCityCollectionDone, PostCityCollectionFail>({
  async handler({
    body
  }) {
    const name = "postCityCollection.body";
    const response = await requestFx({
      path: "/api/cities",
      method: "POST",
      body
    });
    return parseByStatus(name, response, {
      201: ["created", postCityCollectionCreated],
      400: ["bad_request", postCityCollectionBadRequest],
      422: ["unprocessable_entity", postCityCollectionUnprocessableEntity]
    });
  }

});
//#endregion postCityCollection

/* --- */
//#region getCityItem
export type GetCityItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* City resource */
export const getCityItemOk = typed.object({
  "@context": typed.union(typed.string, typed.object({
    "@vocab": typed.string,
    hydra: typed.union("http://www.w3.org/ns/hydra/core#")
  })).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
  state: typed.string,
  description: typed.string.maybe,
  heroImage: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    url: typed.string.maybe
  }),
  cardImage: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    url: typed.string.maybe
  })
});
export type GetCityItemDone = {
  status: "ok";
  answer: typed.Get<typeof getCityItemOk>;
};

/* Resource not found */
export const getCityItemNotFound = typed.nul;
export type GetCityItemFail = {
  status: "not_found";
  error: typed.Get<typeof getCityItemNotFound>;
} | GenericErrors;

/* Retrieves a City resource. */
export const getCityItem = createEffect<GetCityItem, GetCityItemDone, GetCityItemFail>({
  async handler({
    path
  }) {
    const name = "getCityItem.body";
    const response = await requestFx({
      path: `/api/cities/${path.id}`,
      method: "GET"
    });
    return parseByStatus(name, response, {
      200: ["ok", getCityItemOk],
      404: ["not_found", getCityItemNotFound]
    });
  }

});
//#endregion getCityItem

/* --- */
//#region putCityItem
export type PutCityItem = {
  body: {
    name: string;
    state: string;
    description?: string | null;
    heroImage: string;
    cardImage: string;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* City resource updated */
export const putCityItemOk = typed.object({
  "@context": typed.union(typed.string, typed.object({
    "@vocab": typed.string,
    hydra: typed.union("http://www.w3.org/ns/hydra/core#")
  })).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
  state: typed.string,
  description: typed.string.maybe,
  heroImage: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    url: typed.string.maybe
  }),
  cardImage: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    url: typed.string.maybe
  })
});
export type PutCityItemDone = {
  status: "ok";
  answer: typed.Get<typeof putCityItemOk>;
};

/* Invalid input */
export const putCityItemBadRequest = typed.nul;

/* Resource not found */
export const putCityItemNotFound = typed.nul;

/* Unprocessable entity */
export const putCityItemUnprocessableEntity = typed.nul;
export type PutCityItemFail = {
  status: "bad_request";
  error: typed.Get<typeof putCityItemBadRequest>;
} | {
  status: "not_found";
  error: typed.Get<typeof putCityItemNotFound>;
} | {
  status: "unprocessable_entity";
  error: typed.Get<typeof putCityItemUnprocessableEntity>;
} | GenericErrors;

/* Replaces the City resource. */
export const putCityItem = createEffect<PutCityItem, PutCityItemDone, PutCityItemFail>({
  async handler({
    body,
    path
  }) {
    const name = "putCityItem.body";
    const response = await requestFx({
      path: `/api/cities/${path.id}`,
      method: "PUT",
      body
    });
    return parseByStatus(name, response, {
      200: ["ok", putCityItemOk],
      400: ["bad_request", putCityItemBadRequest],
      404: ["not_found", putCityItemNotFound],
      422: ["unprocessable_entity", putCityItemUnprocessableEntity]
    });
  }

});
//#endregion putCityItem

/* --- */
//#region deleteCityItem
export type DeleteCityItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* City resource deleted */
export const deleteCityItemNoContent = typed.nul;
export type DeleteCityItemDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteCityItemNoContent>;
};

/* Resource not found */
export const deleteCityItemNotFound = typed.nul;
export type DeleteCityItemFail = {
  status: "not_found";
  error: typed.Get<typeof deleteCityItemNotFound>;
} | GenericErrors;

/* Removes the City resource. */
export const deleteCityItem = createEffect<DeleteCityItem, DeleteCityItemDone, DeleteCityItemFail>({
  async handler({
    path
  }) {
    const name = "deleteCityItem.body";
    const response = await requestFx({
      path: `/api/cities/${path.id}`,
      method: "DELETE"
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteCityItemNoContent],
      404: ["not_found", deleteCityItemNotFound]
    });
  }

});
//#endregion deleteCityItem

/* --- */
//#region getClientCollection
export type GetClientCollection = {
  query?: {
    /* The collection page number */
    page?: number;

    /* The number of items per page */
    itemsPerPage?: number;

    /* Enable or disable pagination */
    pagination?: boolean;

    /* Search by keyword on full name, email, phone and comment */
    keyword?: string;
  };
};

/* Client collection */
export const getClientCollectionOk = typed.object({
  "hydra:member": typed.array(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string,
      createdAt: typed.string.optional,
      updatedAt: typed.string.maybe,
      spentMoney: typed.string.optional,
      address: typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        address1: typed.string.maybe,
        address2: typed.string.maybe,
        city: typed.string.maybe,
        state: typed.string.maybe,
        zipCode: typed.string.maybe,
      }).optional,
      comment: typed.string.maybe,
    })
  ),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional,
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(
      typed.object({
        "@type": typed.string.optional,
        variable: typed.string.optional,
        property: typed.string.maybe,
        required: typed.boolean.optional,
      })
    ).optional,
  }).optional,
});
export type GetClientCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getClientCollectionOk>;
};
export type GetClientCollectionFail = GenericErrors;

/* Retrieves the collection of Client resources. */
export const getClientCollection = createEffect<
  GetClientCollection,
  GetClientCollectionDone,
  GetClientCollectionFail
>({
  async handler({ query }) {
    const name = "getClientCollection.body";
    const response = await requestFx({
      path: "/api/clients",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getClientCollectionOk],
    });
  },
});
//#endregion getClientCollection

/* --- */
//#region postClientCollection
export type PostClientCollection = {
  body: {
    email: string;
    password?: string;
    firstName: string;
    lastName: string;
    phone: string;
    comment?: string | null;
  };
};

/* Client resource created */
export const postClientCollectionCreated = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  email: typed.string,
  firstName: typed.string,
  lastName: typed.string,
  phone: typed.string,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
  spentMoney: typed.string.optional,
  address: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    address1: typed.string.maybe,
    address2: typed.string.maybe,
    city: typed.string.maybe,
    state: typed.string.maybe,
    zipCode: typed.string.maybe,
  }).optional,
  comment: typed.string.maybe,
});
export type PostClientCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postClientCollectionCreated>;
};

/* Invalid input */
export const postClientCollectionBadRequest = typed.nul;

/* Unprocessable entity */
export const postClientCollectionUnprocessableEntity = typed.nul;
export type PostClientCollectionFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postClientCollectionBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postClientCollectionUnprocessableEntity>;
  }
  | GenericErrors;

/* Creates a Client resource. */
export const postClientCollection = createEffect<
  PostClientCollection,
  PostClientCollectionDone,
  PostClientCollectionFail
>({
  async handler({ body }) {
    const name = "postClientCollection.body";
    const response = await requestFx({
      path: "/api/clients",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postClientCollectionCreated],
      400: ["bad_request", postClientCollectionBadRequest],
      422: ["unprocessable_entity", postClientCollectionUnprocessableEntity],
    });
  },
});
//#endregion postClientCollection

/* --- */
//#region getClientItem
export type GetClientItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Client resource */
export const getClientItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  email: typed.string,
  firstName: typed.string,
  lastName: typed.string,
  phone: typed.string,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
  boats: typed.array(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      manufacturer: typed.string,
      model: typed.string,
      year: typed.number,
      loa: typed.number,
      beam: typed.number,
      draft: typed.number.maybe,
      height: typed.number.maybe,
      isMain: typed.boolean.optional,
      createdAt: typed.string.optional,
      updatedAt: typed.string.optional,
    })
  ).optional,
  spentMoney: typed.string.optional,
  address: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    address1: typed.string.maybe,
    address2: typed.string.maybe,
    city: typed.string.maybe,
    state: typed.string.maybe,
    zipCode: typed.string.maybe,
  }).optional,
});
export type GetClientItemDone = {
  status: "ok";
  answer: typed.Get<typeof getClientItemOk>;
};

/* Resource not found */
export const getClientItemNotFound = typed.nul;
export type GetClientItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getClientItemNotFound>;
  }
  | GenericErrors;

/* Retrieves a Client resource. */
export const getClientItem = createEffect<
  GetClientItem,
  GetClientItemDone,
  GetClientItemFail
>({
  async handler({ path }) {
    const name = "getClientItem.body";
    const response = await requestFx({
      path: `/api/clients/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getClientItemOk],
      404: ["not_found", getClientItemNotFound],
    });
  },
});
//#endregion getClientItem

/* --- */
//#region putClientItem
export type PutClientItem = {
  body: {
    email: string;
    password?: string;
    firstName: string;
    lastName: string;
    phone: string;
    comment?: string | null;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Client resource updated */
export const putClientItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  email: typed.string,
  firstName: typed.string,
  lastName: typed.string,
  phone: typed.string,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
  spentMoney: typed.string.optional,
  address: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    address1: typed.string.maybe,
    address2: typed.string.maybe,
    city: typed.string.maybe,
    state: typed.string.maybe,
    zipCode: typed.string.maybe,
  }).optional,
  comment: typed.string.maybe,
});
export type PutClientItemDone = {
  status: "ok";
  answer: typed.Get<typeof putClientItemOk>;
};

/* Invalid input */
export const putClientItemBadRequest = typed.nul;

/* Resource not found */
export const putClientItemNotFound = typed.nul;

/* Unprocessable entity */
export const putClientItemUnprocessableEntity = typed.nul;
export type PutClientItemFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof putClientItemBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof putClientItemNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof putClientItemUnprocessableEntity>;
  }
  | GenericErrors;

/* Replaces the Client resource. */
export const putClientItem = createEffect<
  PutClientItem,
  PutClientItemDone,
  PutClientItemFail
>({
  async handler({ body, path }) {
    const name = "putClientItem.body";
    const response = await requestFx({
      path: `/api/clients/${path.id}`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putClientItemOk],
      400: ["bad_request", putClientItemBadRequest],
      404: ["not_found", putClientItemNotFound],
      422: ["unprocessable_entity", putClientItemUnprocessableEntity],
    });
  },
});
//#endregion putClientItem

/* --- */
//#region deleteClientItem
export type DeleteClientItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Client resource deleted */
export const deleteClientItemNoContent = typed.nul;
export type DeleteClientItemDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteClientItemNoContent>;
};

/* Resource not found */
export const deleteClientItemNotFound = typed.nul;
export type DeleteClientItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof deleteClientItemNotFound>;
  }
  | GenericErrors;

/* Removes the Client resource. */
export const deleteClientItem = createEffect<
  DeleteClientItem,
  DeleteClientItemDone,
  DeleteClientItemFail
>({
  async handler({ path }) {
    const name = "deleteClientItem.body";
    const response = await requestFx({
      path: `/api/clients/${path.id}`,
      method: "DELETE",
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteClientItemNoContent],
      404: ["not_found", deleteClientItemNotFound],
    });
  },
});
//#endregion deleteClientItem

/* --- */
//#region getCompanyCollection
export type GetCompanyCollection = {
  query?: {
    /* Enable or disable pagination */
    pagination?: boolean;
  };
};

/* Company collection */
export const getCompanyCollectionOk = typed.object({
  "hydra:member": typed.array(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      createdAt: typed.string.optional,
      updatedAt: typed.string.maybe,
    })
  ),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional,
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(
      typed.object({
        "@type": typed.string.optional,
        variable: typed.string.optional,
        property: typed.string.maybe,
        required: typed.boolean.optional,
      })
    ).optional,
  }).optional,
});
export type GetCompanyCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getCompanyCollectionOk>;
};
export type GetCompanyCollectionFail = GenericErrors;

/* Retrieves the collection of Company resources. */
export const getCompanyCollection = createEffect<
  GetCompanyCollection,
  GetCompanyCollectionDone,
  GetCompanyCollectionFail
>({
  async handler({ query }) {
    const name = "getCompanyCollection.body";
    const response = await requestFx({
      path: "/api/companies",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getCompanyCollectionOk],
    });
  },
});
//#endregion getCompanyCollection

/* --- */
//#region postCompanyCollection
export type PostCompanyCollection = {
  body: {
    name: string;
  };
};

/* Company resource created */
export const postCompanyCollectionCreated = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
});
export type PostCompanyCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postCompanyCollectionCreated>;
};

/* Invalid input */
export const postCompanyCollectionBadRequest = typed.nul;

/* Unprocessable entity */
export const postCompanyCollectionUnprocessableEntity = typed.nul;
export type PostCompanyCollectionFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postCompanyCollectionBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postCompanyCollectionUnprocessableEntity>;
  }
  | GenericErrors;

/* Creates a Company resource. */
export const postCompanyCollection = createEffect<
  PostCompanyCollection,
  PostCompanyCollectionDone,
  PostCompanyCollectionFail
>({
  async handler({ body }) {
    const name = "postCompanyCollection.body";
    const response = await requestFx({
      path: "/api/companies",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postCompanyCollectionCreated],
      400: ["bad_request", postCompanyCollectionBadRequest],
      422: ["unprocessable_entity", postCompanyCollectionUnprocessableEntity],
    });
  },
});
//#endregion postCompanyCollection

/* --- */
//#region getCompanyItem
export type GetCompanyItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Company resource */
export const getCompanyItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
});
export type GetCompanyItemDone = {
  status: "ok";
  answer: typed.Get<typeof getCompanyItemOk>;
};

/* Resource not found */
export const getCompanyItemNotFound = typed.nul;
export type GetCompanyItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getCompanyItemNotFound>;
  }
  | GenericErrors;

/* Retrieves a Company resource. */
export const getCompanyItem = createEffect<
  GetCompanyItem,
  GetCompanyItemDone,
  GetCompanyItemFail
>({
  async handler({ path }) {
    const name = "getCompanyItem.body";
    const response = await requestFx({
      path: `/api/companies/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getCompanyItemOk],
      404: ["not_found", getCompanyItemNotFound],
    });
  },
});
//#endregion getCompanyItem

/* --- */
//#region putCompanyItem
export type PutCompanyItem = {
  body: {
    name: string;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Company resource updated */
export const putCompanyItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
});
export type PutCompanyItemDone = {
  status: "ok";
  answer: typed.Get<typeof putCompanyItemOk>;
};

/* Invalid input */
export const putCompanyItemBadRequest = typed.nul;

/* Resource not found */
export const putCompanyItemNotFound = typed.nul;

/* Unprocessable entity */
export const putCompanyItemUnprocessableEntity = typed.nul;
export type PutCompanyItemFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof putCompanyItemBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof putCompanyItemNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof putCompanyItemUnprocessableEntity>;
  }
  | GenericErrors;

/* Replaces the Company resource. */
export const putCompanyItem = createEffect<
  PutCompanyItem,
  PutCompanyItemDone,
  PutCompanyItemFail
>({
  async handler({ body, path }) {
    const name = "putCompanyItem.body";
    const response = await requestFx({
      path: `/api/companies/${path.id}`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putCompanyItemOk],
      400: ["bad_request", putCompanyItemBadRequest],
      404: ["not_found", putCompanyItemNotFound],
      422: ["unprocessable_entity", putCompanyItemUnprocessableEntity],
    });
  },
});
//#endregion putCompanyItem

/* --- */
//#region deleteCompanyItem
export type DeleteCompanyItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Company resource deleted */
export const deleteCompanyItemNoContent = typed.nul;
export type DeleteCompanyItemDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteCompanyItemNoContent>;
};

/* Resource not found */
export const deleteCompanyItemNotFound = typed.nul;
export type DeleteCompanyItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof deleteCompanyItemNotFound>;
  }
  | GenericErrors;

/* Removes the Company resource. */
export const deleteCompanyItem = createEffect<
  DeleteCompanyItem,
  DeleteCompanyItemDone,
  DeleteCompanyItemFail
>({
  async handler({ path }) {
    const name = "deleteCompanyItem.body";
    const response = await requestFx({
      path: `/api/companies/${path.id}`,
      method: "DELETE",
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteCompanyItemNoContent],
      404: ["not_found", deleteCompanyItemNotFound],
    });
  },
});
//#endregion deleteCompanyItem

/* --- */
//#region getDockFilterSchedule
export type GetDockFilterSchedule = {
  query: {
    boatId?: number;
    loa?: number;
    hours: number;
    monthStartDate: string;
  };
};

/* Get schedule */
export const getDockFilterScheduleOk = typed.array(typed.boolean);
export type GetDockFilterScheduleDone = {
  status: "ok";
  answer: typed.Get<typeof getDockFilterScheduleOk>;
};

/* Invalid input */
export const getDockFilterScheduleBadRequest = typed.nul;

/* Unprocessable entity */
export const getDockFilterScheduleUnprocessableEntity = typed.nul;
export type GetDockFilterScheduleFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof getDockFilterScheduleBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof getDockFilterScheduleUnprocessableEntity>;
  }
  | GenericErrors;
export const getDockFilterSchedule = createEffect<
  GetDockFilterSchedule,
  GetDockFilterScheduleDone,
  GetDockFilterScheduleFail
>({
  async handler({ query }) {
    const name = "getDockFilterSchedule.body";
    const response = await requestFx({
      path: "/api/dock-filter-schedule",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getDockFilterScheduleOk],
      400: ["bad_request", getDockFilterScheduleBadRequest],
      422: ["unprocessable_entity", getDockFilterScheduleUnprocessableEntity],
    });
  },
});
//#endregion getDockFilterSchedule

/* --- */
//#region postDockImageCollection
export type PostDockImageCollection = {
  body?: {
    file?: string;
  };
};

/* DockImage resource created */
export const postDockImageCollectionCreated = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  url: typed.string.maybe,
});
export type PostDockImageCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postDockImageCollectionCreated>;
};

/* Invalid input */
export const postDockImageCollectionBadRequest = typed.nul;

/* Unprocessable entity */
export const postDockImageCollectionUnprocessableEntity = typed.nul;
export type PostDockImageCollectionFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postDockImageCollectionBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postDockImageCollectionUnprocessableEntity>;
  }
  | GenericErrors;

/* Creates a DockImage resource. */
export const postDockImageCollection = createEffect<
  PostDockImageCollection,
  PostDockImageCollectionDone,
  PostDockImageCollectionFail
>({
  async handler({ body }) {
    const name = "postDockImageCollection.body";
    const response = await requestFx({
      path: "/api/dock-images",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postDockImageCollectionCreated],
      400: ["bad_request", postDockImageCollectionBadRequest],
      422: ["unprocessable_entity", postDockImageCollectionUnprocessableEntity],
    });
  },
});
//#endregion postDockImageCollection

/* --- */
//#region getDockImageItem
export type GetDockImageItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* DockImage resource */
export const getDockImageItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  url: typed.string.maybe,
});
export type GetDockImageItemDone = {
  status: "ok";
  answer: typed.Get<typeof getDockImageItemOk>;
};

/* Resource not found */
export const getDockImageItemNotFound = typed.nul;
export type GetDockImageItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getDockImageItemNotFound>;
  }
  | GenericErrors;

/* Retrieves a DockImage resource. */
export const getDockImageItem = createEffect<
  GetDockImageItem,
  GetDockImageItemDone,
  GetDockImageItemFail
>({
  async handler({ path }) {
    const name = "getDockImageItem.body";
    const response = await requestFx({
      path: `/api/dock-images/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getDockImageItemOk],
      404: ["not_found", getDockImageItemNotFound],
    });
  },
});
//#endregion getDockImageItem

/* --- */
//#region getDockCollection
export type GetDockCollection = {
  query?: {
    /* The collection page number */
    page?: number;

    /* The number of items per page */
    itemsPerPage?: number;

    /* Enable or disable pagination */
    pagination?: boolean;

    /* OrSearchFilter on name, address, restaurants.name */
    keyword?: string;

    /* Boat ID */
    boat?: string;

    /* Boat LOA */
    loa?: string;
    hours?: string;
    date?: string;

    /* City ID */
    cityId?: string;
    company?: string;
    "company[]"?: string[];
  };
};

/* Dock collection */
export const getDockCollectionOk = typed.object({
  "hydra:member": typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    company: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      bookingFee: typed.string,
      bookingFeeType: typed.union("constant", "percent"),
      minimumBookingFee: typed.string.maybe
    }).optional,
    image: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    }),
    name: typed.string,
    description: typed.string.maybe,
    cityObject: typed.union(typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      state: typed.string,
      description: typed.string.maybe,
      heroImage: typed.object({
        "@context": typed.union(typed.string, typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#")
        })).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        id: typed.number.optional,
        url: typed.string.maybe
      }),
      cardImage: typed.object({
        "@context": typed.union(typed.string, typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#")
        })).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        id: typed.number.optional,
        url: typed.string.maybe
      })
    })),
    address: typed.string,
    city: typed.string.optional,
    state: typed.string.optional,
    latitude: typed.string.maybe,
    longitude: typed.string.maybe,
    zipCode: typed.string,
    phone: typed.string,
    email: typed.string,
    length: typed.number,
    defaultPrice: typed.string,
    prices: typed.array(typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      lengthFrom: typed.number,
      lengthTo: typed.number,
      price: typed.string
    })).optional,
    taxRate: typed.string,
    outOfSeasonTimeFrom: typed.string.maybe,
    outOfSeasonTimeTo: typed.string.maybe,
    operationalHours: typed.array(typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,

      /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
      dayOfWeek: typed.number,
      timeFrom: typed.string,
      timeTo: typed.string
    })).optional,
    restaurants: typed.array(typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      type: typed.string.maybe,
      name: typed.string,
      url: typed.string.maybe,
      distance: typed.number.maybe
    })).optional,
    createdAt: typed.string.optional,
    updatedAt: typed.string.maybe,
    addons: typed.array(typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      addon: typed.object({
        "@context": typed.union(typed.string, typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#")
        })).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        id: typed.number.optional,
        name: typed.string.optional
      }),
      description: typed.string.maybe,
      price: typed.string,
      priceType: typed.union("constant", "percent"),
      tax: typed.union("constant", "percent").maybe,
      taxType: typed.string.maybe
    })).optional,
    terms: typed.string.maybe
  })),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(typed.object({
      "@type": typed.string.optional,
      variable: typed.string.optional,
      property: typed.string.maybe,
      required: typed.boolean.optional
    })).optional
  }).optional
});
export type GetDockCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getDockCollectionOk>;
};
export type GetDockCollectionFail = GenericErrors;

/* Retrieves the collection of Dock resources. */
export const getDockCollection = createEffect<GetDockCollection, GetDockCollectionDone, GetDockCollectionFail>({
  async handler({
    query
  }) {
    const name = "getDockCollection.body";
    const response = await requestFx({
      path: "/api/docks",
      method: "GET",
      query
    });
    return parseByStatus(name, response, {
      200: ["ok", getDockCollectionOk]
    });
  }

});
//#endregion getDockCollection

/* --- */
//#region postDockCollection
export type PostDockCollection = {
  body: {
    image: string;
    name: string;
    description?: string | null;
    cityObject: string | null;
    address: string;
    city?: string;
    state?: string;
    latitude?: string | null;
    longitude?: string | null;
    zipCode: string;
    phone: string;
    email: string;
    length: number;
    defaultPrice: string;
    prices?: {
      lengthFrom: number;
      lengthTo: number;
      price: string;
    }[];
    taxRate: string;
    outOfSeasonTimeFrom?: string | null;
    outOfSeasonTimeTo?: string | null;
    operationalHours?: {
      /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
      dayOfWeek: number;
      timeFrom: string;
      timeTo: string;
    }[];
    restaurants?: {
      type?: string | null;
      name: string;
      url?: string | null;
      distance?: number | null;
    }[];
    addons?: {
      addon: string;
      description?: string | null;
      price: string;
      priceType: "constant" | "percent";
      tax?: ("constant" | "percent") | null;
      taxType?: string | null;
    }[];
    terms?: string | null;
  };
};

/* Dock resource created */
export const postDockCollectionCreated = typed.object({
  "@context": typed.union(typed.string, typed.object({
    "@vocab": typed.string,
    hydra: typed.union("http://www.w3.org/ns/hydra/core#")
  })).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  company: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
    bookingFee: typed.string,
    bookingFeeType: typed.union("constant", "percent"),
    minimumBookingFee: typed.string.maybe
  }).optional,
  image: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    url: typed.string.maybe
  }),
  name: typed.string,
  description: typed.string.maybe,
  cityObject: typed.union(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
    state: typed.string,
    description: typed.string.maybe,
    heroImage: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    }),
    cardImage: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    })
  })),
  address: typed.string,
  city: typed.string.optional,
  state: typed.string.optional,
  latitude: typed.string.maybe,
  longitude: typed.string.maybe,
  zipCode: typed.string,
  phone: typed.string,
  email: typed.string,
  length: typed.number,
  defaultPrice: typed.string,
  prices: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    lengthFrom: typed.number,
    lengthTo: typed.number,
    price: typed.string
  })).optional,
  taxRate: typed.string,
  outOfSeasonTimeFrom: typed.string.maybe,
  outOfSeasonTimeTo: typed.string.maybe,
  operationalHours: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,

    /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
    dayOfWeek: typed.number,
    timeFrom: typed.string,
    timeTo: typed.string
  })).optional,
  restaurants: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    type: typed.string.maybe,
    name: typed.string,
    url: typed.string.maybe,
    distance: typed.number.maybe
  })).optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
  addons: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    addon: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string.optional
    }),
    description: typed.string.maybe,
    price: typed.string,
    priceType: typed.union("constant", "percent"),
    tax: typed.union("constant", "percent").maybe,
    taxType: typed.string.maybe
  })).optional,
  terms: typed.string.maybe
});
export type PostDockCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postDockCollectionCreated>;
};

/* Invalid input */
export const postDockCollectionBadRequest = typed.nul;

/* Unprocessable entity */
export const postDockCollectionUnprocessableEntity = typed.nul;
export type PostDockCollectionFail = {
  status: "bad_request";
  error: typed.Get<typeof postDockCollectionBadRequest>;
} | {
  status: "unprocessable_entity";
  error: typed.Get<typeof postDockCollectionUnprocessableEntity>;
} | GenericErrors;

/* Creates a Dock resource. */
export const postDockCollection = createEffect<PostDockCollection, PostDockCollectionDone, PostDockCollectionFail>({
  async handler({
    body
  }) {
    const name = "postDockCollection.body";
    const response = await requestFx({
      path: "/api/docks",
      method: "POST",
      body
    });
    return parseByStatus(name, response, {
      201: ["created", postDockCollectionCreated],
      400: ["bad_request", postDockCollectionBadRequest],
      422: ["unprocessable_entity", postDockCollectionUnprocessableEntity]
    });
  }

});
//#endregion postDockCollection

/* --- */
//#region getDockItem
export type GetDockItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Dock resource */
export const getDockItemOk = typed.object({
  "@context": typed.union(typed.string, typed.object({
    "@vocab": typed.string,
    hydra: typed.union("http://www.w3.org/ns/hydra/core#")
  })).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  company: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
    bookingFee: typed.string,
    bookingFeeType: typed.union("constant", "percent"),
    minimumBookingFee: typed.string.maybe
  }).optional,
  image: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    url: typed.string.maybe
  }),
  name: typed.string,
  description: typed.string.maybe,
  cityObject: typed.union(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
    state: typed.string,
    description: typed.string.maybe,
    heroImage: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    }),
    cardImage: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    })
  })),
  address: typed.string,
  city: typed.string.optional,
  state: typed.string.optional,
  latitude: typed.string.maybe,
  longitude: typed.string.maybe,
  zipCode: typed.string,
  phone: typed.string,
  email: typed.string,
  length: typed.number,
  defaultPrice: typed.string,
  prices: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    lengthFrom: typed.number,
    lengthTo: typed.number,
    price: typed.string
  })).optional,
  taxRate: typed.string,
  outOfSeasonTimeFrom: typed.string.maybe,
  outOfSeasonTimeTo: typed.string.maybe,
  operationalHours: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,

    /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
    dayOfWeek: typed.number,
    timeFrom: typed.string,
    timeTo: typed.string
  })).optional,
  restaurants: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    type: typed.string.maybe,
    name: typed.string,
    url: typed.string.maybe,
    distance: typed.number.maybe
  })).optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
  addons: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    addon: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string.optional
    }),
    description: typed.string.maybe,
    price: typed.string,
    priceType: typed.union("constant", "percent"),
    tax: typed.union("constant", "percent").maybe,
    taxType: typed.string.maybe
  })).optional,
  terms: typed.string.maybe
});
export type GetDockItemDone = {
  status: "ok";
  answer: typed.Get<typeof getDockItemOk>;
};

/* Resource not found */
export const getDockItemNotFound = typed.nul;
export type GetDockItemFail = {
  status: "not_found";
  error: typed.Get<typeof getDockItemNotFound>;
} | GenericErrors;

/* Retrieves a Dock resource. */
export const getDockItem = createEffect<GetDockItem, GetDockItemDone, GetDockItemFail>({
  async handler({
    path
  }) {
    const name = "getDockItem.body";
    const response = await requestFx({
      path: `/api/docks/${path.id}`,
      method: "GET"
    });
    return parseByStatus(name, response, {
      200: ["ok", getDockItemOk],
      404: ["not_found", getDockItemNotFound]
    });
  }

});
//#endregion getDockItem

/* --- */
//#region putDockItem
export type PutDockItem = {
  body: {
    image: string;
    name: string;
    description?: string | null;
    cityObject: string | null;
    address: string;
    city?: string;
    state?: string;
    latitude?: string | null;
    longitude?: string | null;
    zipCode: string;
    phone: string;
    email: string;
    length: number;
    defaultPrice: string;
    prices?: {
      lengthFrom: number;
      lengthTo: number;
      price: string;
    }[];
    taxRate: string;
    outOfSeasonTimeFrom?: string | null;
    outOfSeasonTimeTo?: string | null;
    operationalHours?: {
      /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
      dayOfWeek: number;
      timeFrom: string;
      timeTo: string;
    }[];
    restaurants?: {
      type?: string | null;
      name: string;
      url?: string | null;
      distance?: number | null;
    }[];
    addons?: {
      /* On update, use "id": "/api/dock-addons/{id}" to edit saved dock's addon. */
      id?: string | null;
      addon: string;
      description?: string | null;
      price: string;
      priceType: "constant" | "percent";
      tax?: ("constant" | "percent") | null;
      taxType?: string | null;
    }[];
    terms?: string | null;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Dock resource updated */
export const putDockItemOk = typed.object({
  "@context": typed.union(typed.string, typed.object({
    "@vocab": typed.string,
    hydra: typed.union("http://www.w3.org/ns/hydra/core#")
  })).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  company: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
    bookingFee: typed.string,
    bookingFeeType: typed.union("constant", "percent"),
    minimumBookingFee: typed.string.maybe
  }).optional,
  image: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    url: typed.string.maybe
  }),
  name: typed.string,
  description: typed.string.maybe,
  cityObject: typed.union(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
    state: typed.string,
    description: typed.string.maybe,
    heroImage: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    }),
    cardImage: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    })
  })),
  address: typed.string,
  city: typed.string.optional,
  state: typed.string.optional,
  latitude: typed.string.maybe,
  longitude: typed.string.maybe,
  zipCode: typed.string,
  phone: typed.string,
  email: typed.string,
  length: typed.number,
  defaultPrice: typed.string,
  prices: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    lengthFrom: typed.number,
    lengthTo: typed.number,
    price: typed.string
  })).optional,
  taxRate: typed.string,
  outOfSeasonTimeFrom: typed.string.maybe,
  outOfSeasonTimeTo: typed.string.maybe,
  operationalHours: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,

    /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
    dayOfWeek: typed.number,
    timeFrom: typed.string,
    timeTo: typed.string
  })).optional,
  restaurants: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    type: typed.string.maybe,
    name: typed.string,
    url: typed.string.maybe,
    distance: typed.number.maybe
  })).optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
  addons: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    addon: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string.optional
    }),
    description: typed.string.maybe,
    price: typed.string,
    priceType: typed.union("constant", "percent"),
    tax: typed.union("constant", "percent").maybe,
    taxType: typed.string.maybe
  })).optional,
  terms: typed.string.maybe
});
export type PutDockItemDone = {
  status: "ok";
  answer: typed.Get<typeof putDockItemOk>;
};

/* Invalid input */
export const putDockItemBadRequest = typed.nul;

/* Resource not found */
export const putDockItemNotFound = typed.nul;

/* Unprocessable entity */
export const putDockItemUnprocessableEntity = typed.nul;
export type PutDockItemFail = {
  status: "bad_request";
  error: typed.Get<typeof putDockItemBadRequest>;
} | {
  status: "not_found";
  error: typed.Get<typeof putDockItemNotFound>;
} | {
  status: "unprocessable_entity";
  error: typed.Get<typeof putDockItemUnprocessableEntity>;
} | GenericErrors;

/* Replaces the Dock resource. */
export const putDockItem = createEffect<PutDockItem, PutDockItemDone, PutDockItemFail>({
  async handler({
    body,
    path
  }) {
    const name = "putDockItem.body";
    const response = await requestFx({
      path: `/api/docks/${path.id}`,
      method: "PUT",
      body
    });
    return parseByStatus(name, response, {
      200: ["ok", putDockItemOk],
      400: ["bad_request", putDockItemBadRequest],
      404: ["not_found", putDockItemNotFound],
      422: ["unprocessable_entity", putDockItemUnprocessableEntity]
    });
  }

});
//#endregion putDockItem

/* --- */
//#region deleteDockItem
export type DeleteDockItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Dock resource deleted */
export const deleteDockItemNoContent = typed.nul;
export type DeleteDockItemDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteDockItemNoContent>;
};

/* Resource not found */
export const deleteDockItemNotFound = typed.nul;
export type DeleteDockItemFail = {
  status: "not_found";
  error: typed.Get<typeof deleteDockItemNotFound>;
} | GenericErrors;

/* Removes the Dock resource. */
export const deleteDockItem = createEffect<DeleteDockItem, DeleteDockItemDone, DeleteDockItemFail>({
  async handler({
    path
  }) {
    const name = "deleteDockItem.body";
    const response = await requestFx({
      path: `/api/docks/${path.id}`,
      method: "DELETE"
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteDockItemNoContent],
      404: ["not_found", deleteDockItemNotFound]
    });
  }

});
//#endregion deleteDockItem

/* --- */
//#region putTermsDockItem
export type PutTermsDockItem = {
  body: {
    terms?: string | null;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Dock resource updated */
export const putTermsDockItemOk = typed.object({
  "@context": typed.union(typed.string, typed.object({
    "@vocab": typed.string,
    hydra: typed.union("http://www.w3.org/ns/hydra/core#")
  })).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  company: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
    bookingFee: typed.string,
    bookingFeeType: typed.union("constant", "percent"),
    minimumBookingFee: typed.string.maybe
  }).optional,
  image: typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    url: typed.string.maybe
  }),
  name: typed.string,
  description: typed.string.maybe,
  cityObject: typed.union(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
    state: typed.string,
    description: typed.string.maybe,
    heroImage: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    }),
    cardImage: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      url: typed.string.maybe
    })
  })),
  address: typed.string,
  city: typed.string.optional,
  state: typed.string.optional,
  latitude: typed.string.maybe,
  longitude: typed.string.maybe,
  zipCode: typed.string,
  phone: typed.string,
  email: typed.string,
  length: typed.number,
  defaultPrice: typed.string,
  prices: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    lengthFrom: typed.number,
    lengthTo: typed.number,
    price: typed.string
  })).optional,
  taxRate: typed.string,
  outOfSeasonTimeFrom: typed.string.maybe,
  outOfSeasonTimeTo: typed.string.maybe,
  operationalHours: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,

    /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
    dayOfWeek: typed.number,
    timeFrom: typed.string,
    timeTo: typed.string
  })).optional,
  restaurants: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    type: typed.string.maybe,
    name: typed.string,
    url: typed.string.maybe,
    distance: typed.number.maybe
  })).optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
  addons: typed.array(typed.object({
    "@context": typed.union(typed.string, typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#")
    })).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    addon: typed.object({
      "@context": typed.union(typed.string, typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#")
      })).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string.optional
    }),
    description: typed.string.maybe,
    price: typed.string,
    priceType: typed.union("constant", "percent"),
    tax: typed.union("constant", "percent").maybe,
    taxType: typed.string.maybe
  })).optional,
  terms: typed.string.maybe
});
export type PutTermsDockItemDone = {
  status: "ok";
  answer: typed.Get<typeof putTermsDockItemOk>;
};

/* Invalid input */
export const putTermsDockItemBadRequest = typed.nul;

/* Resource not found */
export const putTermsDockItemNotFound = typed.nul;

/* Unprocessable entity */
export const putTermsDockItemUnprocessableEntity = typed.nul;
export type PutTermsDockItemFail = {
  status: "bad_request";
  error: typed.Get<typeof putTermsDockItemBadRequest>;
} | {
  status: "not_found";
  error: typed.Get<typeof putTermsDockItemNotFound>;
} | {
  status: "unprocessable_entity";
  error: typed.Get<typeof putTermsDockItemUnprocessableEntity>;
} | GenericErrors;

/* Replaces the Dock resource. */
export const putTermsDockItem = createEffect<PutTermsDockItem, PutTermsDockItemDone, PutTermsDockItemFail>({
  async handler({
    body,
    path
  }) {
    const name = "putTermsDockItem.body";
    const response = await requestFx({
      path: `/api/docks/${path.id}/terms`,
      method: "PUT",
      body
    });
    return parseByStatus(name, response, {
      200: ["ok", putTermsDockItemOk],
      400: ["bad_request", putTermsDockItemBadRequest],
      404: ["not_found", putTermsDockItemNotFound],
      422: ["unprocessable_entity", putTermsDockItemUnprocessableEntity]
    });
  }

});
//#endregion putTermsDockItem

/* --- */
//#region getManagerCollection
export type GetManagerCollection = {
  query?: {
    /* The collection page number */
    page?: number;

    /* The number of items per page */
    itemsPerPage?: number;

    /* Enable or disable pagination */
    pagination?: boolean;

    /* Search by keyword on full name, email, phone */
    keyword?: string;
  };
};

/* Manager collection */
export const getManagerCollectionOk = typed.object({
  "hydra:member": typed.array(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      company: typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        id: typed.number.optional,
        name: typed.string,
      }).optional,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string.maybe,
      createdAt: typed.string.optional,
      updatedAt: typed.string.maybe,
    })
  ),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional,
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(
      typed.object({
        "@type": typed.string.optional,
        variable: typed.string.optional,
        property: typed.string.maybe,
        required: typed.boolean.optional,
      })
    ).optional,
  }).optional,
});
export type GetManagerCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getManagerCollectionOk>;
};
export type GetManagerCollectionFail = GenericErrors;

/* Retrieves the collection of Manager resources. */
export const getManagerCollection = createEffect<
  GetManagerCollection,
  GetManagerCollectionDone,
  GetManagerCollectionFail
>({
  async handler({ query }) {
    const name = "getManagerCollection.body";
    const response = await requestFx({
      path: "/api/managers",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getManagerCollectionOk],
    });
  },
});
//#endregion getManagerCollection

/* --- */
//#region postManagerCollection
export type PostManagerCollection = {
  body: {
    email: string;
    password?: string;
    company?: string;
    firstName: string;
    lastName: string;
    phone: string | null;
  };
};

/* Manager resource created */
export const postManagerCollectionCreated = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  email: typed.string,
  company: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
  }).optional,
  firstName: typed.string,
  lastName: typed.string,
  phone: typed.string.maybe,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
});
export type PostManagerCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postManagerCollectionCreated>;
};

/* Invalid input */
export const postManagerCollectionBadRequest = typed.nul;

/* Unprocessable entity */
export const postManagerCollectionUnprocessableEntity = typed.nul;
export type PostManagerCollectionFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postManagerCollectionBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postManagerCollectionUnprocessableEntity>;
  }
  | GenericErrors;

/* Creates a Manager resource. */
export const postManagerCollection = createEffect<
  PostManagerCollection,
  PostManagerCollectionDone,
  PostManagerCollectionFail
>({
  async handler({ body }) {
    const name = "postManagerCollection.body";
    const response = await requestFx({
      path: "/api/managers",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postManagerCollectionCreated],
      400: ["bad_request", postManagerCollectionBadRequest],
      422: ["unprocessable_entity", postManagerCollectionUnprocessableEntity],
    });
  },
});
//#endregion postManagerCollection

/* --- */
//#region getManagerItem
export type GetManagerItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Manager resource */
export const getManagerItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  email: typed.string,
  company: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
  }).optional,
  firstName: typed.string,
  lastName: typed.string,
  phone: typed.string.maybe,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
});
export type GetManagerItemDone = {
  status: "ok";
  answer: typed.Get<typeof getManagerItemOk>;
};

/* Resource not found */
export const getManagerItemNotFound = typed.nul;
export type GetManagerItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getManagerItemNotFound>;
  }
  | GenericErrors;

/* Retrieves a Manager resource. */
export const getManagerItem = createEffect<
  GetManagerItem,
  GetManagerItemDone,
  GetManagerItemFail
>({
  async handler({ path }) {
    const name = "getManagerItem.body";
    const response = await requestFx({
      path: `/api/managers/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getManagerItemOk],
      404: ["not_found", getManagerItemNotFound],
    });
  },
});
//#endregion getManagerItem

/* --- */
//#region putManagerItem
export type PutManagerItem = {
  body: {
    email: string;
    password?: string;
    company?: string;
    firstName: string;
    lastName: string;
    phone: string | null;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Manager resource updated */
export const putManagerItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  email: typed.string,
  company: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    name: typed.string,
  }).optional,
  firstName: typed.string,
  lastName: typed.string,
  phone: typed.string.maybe,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
});
export type PutManagerItemDone = {
  status: "ok";
  answer: typed.Get<typeof putManagerItemOk>;
};

/* Invalid input */
export const putManagerItemBadRequest = typed.nul;

/* Resource not found */
export const putManagerItemNotFound = typed.nul;

/* Unprocessable entity */
export const putManagerItemUnprocessableEntity = typed.nul;
export type PutManagerItemFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof putManagerItemBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof putManagerItemNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof putManagerItemUnprocessableEntity>;
  }
  | GenericErrors;

/* Replaces the Manager resource. */
export const putManagerItem = createEffect<
  PutManagerItem,
  PutManagerItemDone,
  PutManagerItemFail
>({
  async handler({ body, path }) {
    const name = "putManagerItem.body";
    const response = await requestFx({
      path: `/api/managers/${path.id}`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putManagerItemOk],
      400: ["bad_request", putManagerItemBadRequest],
      404: ["not_found", putManagerItemNotFound],
      422: ["unprocessable_entity", putManagerItemUnprocessableEntity],
    });
  },
});
//#endregion putManagerItem

/* --- */
//#region deleteManagerItem
export type DeleteManagerItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Manager resource deleted */
export const deleteManagerItemNoContent = typed.nul;
export type DeleteManagerItemDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteManagerItemNoContent>;
};

/* Resource not found */
export const deleteManagerItemNotFound = typed.nul;
export type DeleteManagerItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof deleteManagerItemNotFound>;
  }
  | GenericErrors;

/* Removes the Manager resource. */
export const deleteManagerItem = createEffect<
  DeleteManagerItem,
  DeleteManagerItemDone,
  DeleteManagerItemFail
>({
  async handler({ path }) {
    const name = "deleteManagerItem.body";
    const response = await requestFx({
      path: `/api/managers/${path.id}`,
      method: "DELETE",
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteManagerItemNoContent],
      404: ["not_found", deleteManagerItemNotFound],
    });
  },
});
//#endregion deleteManagerItem

/* --- */
//#region getManufacturers
export type GetManufacturers = {};
export const getManufacturersOk = typed.array(
  typed.object({
    id: typed.number.optional,
    name: typed.string.optional,
  })
);
export type GetManufacturersDone = {
  status: "ok";
  answer: typed.Get<typeof getManufacturersOk>;
};
export type GetManufacturersFail = GenericErrors;
export const getManufacturers = createEffect<
  GetManufacturers,
  GetManufacturersDone,
  GetManufacturersFail
>({
  async handler() {
    const name = "getManufacturers.body";
    const response = await requestFx({
      path: "/api/manufacturers",
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getManufacturersOk],
    });
  },
});
//#endregion getManufacturers

/* --- */
//#region getModelsByManufacturer
export type GetModelsByManufacturer = {
  path: {
    manufacturerId: number;
  };
};
export const getModelsByManufacturerOk = typed.array(
  typed.object({
    id: typed.number.optional,
    name: typed.string.optional,
  })
);
export type GetModelsByManufacturerDone = {
  status: "ok";
  answer: typed.Get<typeof getModelsByManufacturerOk>;
};
export type GetModelsByManufacturerFail = GenericErrors;
export const getModelsByManufacturer = createEffect<
  GetModelsByManufacturer,
  GetModelsByManufacturerDone,
  GetModelsByManufacturerFail
>({
  async handler({ path }) {
    const name = "getModelsByManufacturer.body";
    const response = await requestFx({
      path: `/api/manufacturers/${path.manufacturerId}/models`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getModelsByManufacturerOk],
    });
  },
});
//#endregion getModelsByManufacturer

/* --- */
//#region getYearsByManufacturerModel
export type GetYearsByManufacturerModel = {
  path: {
    manufacturerId: number;
    modelId: number;
  };
};
export const getYearsByManufacturerModelOk = typed.array(
  typed.object({
    year: typed.number.optional,
  })
);
export type GetYearsByManufacturerModelDone = {
  status: "ok";
  answer: typed.Get<typeof getYearsByManufacturerModelOk>;
};
export type GetYearsByManufacturerModelFail = GenericErrors;
export const getYearsByManufacturerModel = createEffect<
  GetYearsByManufacturerModel,
  GetYearsByManufacturerModelDone,
  GetYearsByManufacturerModelFail
>({
  async handler({ path }) {
    const name = "getYearsByManufacturerModel.body";
    const response = await requestFx({
      path: `/api/manufacturers/${path.manufacturerId}/models/${path.modelId}/years`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getYearsByManufacturerModelOk],
    });
  },
});
//#endregion getYearsByManufacturerModel

/* --- */
//#region getBoatsByManufacturerModelYear
export type GetBoatsByManufacturerModelYear = {
  path: {
    manufacturerId: number;
    modelId: number;
    year: number;
  };
};
export const getBoatsByManufacturerModelYearOk = typed.array(
  typed.object({
    loa: typed.number.optional,
    beam: typed.number.optional,
  })
);
export type GetBoatsByManufacturerModelYearDone = {
  status: "ok";
  answer: typed.Get<typeof getBoatsByManufacturerModelYearOk>;
};
export type GetBoatsByManufacturerModelYearFail = GenericErrors;
export const getBoatsByManufacturerModelYear = createEffect<
  GetBoatsByManufacturerModelYear,
  GetBoatsByManufacturerModelYearDone,
  GetBoatsByManufacturerModelYearFail
>({
  async handler({ path }) {
    const name = "getBoatsByManufacturerModelYear.body";
    const response = await requestFx({
      path: `/api/manufacturers/${path.manufacturerId}/models/${path.modelId}/years/${path.year}/boats`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getBoatsByManufacturerModelYearOk],
    });
  },
});
//#endregion getBoatsByManufacturerModelYear

/* --- */
//#region getReasonCollection
export type GetReasonCollection = {
  query?: {
    /* Enable or disable pagination */
    pagination?: boolean;
  };
};

/* Reason collection */
export const getReasonCollectionOk = typed.object({
  "hydra:member": typed.array(
    typed.object({
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string.optional,
    })
  ),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional,
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(
      typed.object({
        "@type": typed.string.optional,
        variable: typed.string.optional,
        property: typed.string.maybe,
        required: typed.boolean.optional,
      })
    ).optional,
  }).optional,
});
export type GetReasonCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getReasonCollectionOk>;
};
export type GetReasonCollectionFail = GenericErrors;

/* Retrieves the collection of Reason resources. */
export const getReasonCollection = createEffect<
  GetReasonCollection,
  GetReasonCollectionDone,
  GetReasonCollectionFail
>({
  async handler({ query }) {
    const name = "getReasonCollection.body";
    const response = await requestFx({
      path: "/api/reasons",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getReasonCollectionOk],
    });
  },
});
//#endregion getReasonCollection

/* --- */
//#region getReasonItem
export type GetReasonItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Reason resource */
export const getReasonItemNoContent = typed.object({});
export type GetReasonItemDone = {
  status: "no_content";
  answer: typed.Get<typeof getReasonItemNoContent>;
};

/* Resource not found */
export const getReasonItemNotFound = typed.nul;
export type GetReasonItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getReasonItemNotFound>;
  }
  | GenericErrors;

/* Retrieves a Reason resource. */
export const getReasonItem = createEffect<
  GetReasonItem,
  GetReasonItemDone,
  GetReasonItemFail
>({
  async handler({ path }) {
    const name = "getReasonItem.body";
    const response = await requestFx({
      path: `/api/reasons/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      204: ["no_content", getReasonItemNoContent],
      404: ["not_found", getReasonItemNotFound],
    });
  },
});
//#endregion getReasonItem

/* --- */
//#region getReservationAuthorCollection
export type GetReservationAuthorCollection = {
  query?: {
    /* The collection page number */
    page?: number;

    /* The number of items per page */
    itemsPerPage?: number;

    /* Enable or disable pagination */
    pagination?: boolean;

    /* Search by keyword on name and email */
    search?: string;
  };
};

/* ReservationAuthor collection */
export const getReservationAuthorCollectionOk = typed.object({
  "hydra:member": typed.array(
    typed.object({
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      id: typed.string.optional,
      name: typed.string.optional,
      email: typed.string.optional,
      phone: typed.string.optional,
    })
  ),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional,
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(
      typed.object({
        "@type": typed.string.optional,
        variable: typed.string.optional,
        property: typed.string.maybe,
        required: typed.boolean.optional,
      })
    ).optional,
  }).optional,
});
export type GetReservationAuthorCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getReservationAuthorCollectionOk>;
};
export type GetReservationAuthorCollectionFail = GenericErrors;

/* Retrieves the collection of ReservationAuthor resources. */
export const getReservationAuthorCollection = createEffect<
  GetReservationAuthorCollection,
  GetReservationAuthorCollectionDone,
  GetReservationAuthorCollectionFail
>({
  async handler({ query }) {
    const name = "getReservationAuthorCollection.body";
    const response = await requestFx({
      path: "/api/reservation-authors",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getReservationAuthorCollectionOk],
    });
  },
});
//#endregion getReservationAuthorCollection

/* --- */
//#region postReservationCalculatePrice
export type PostReservationCalculatePrice = {
  body?: {
    dock?: string;
    loa?: number;
    beam?: number;
    timeFrom?: string;
    timeTo?: string;
    beforeTax?: string;
    isCharter?: boolean;
  };
};

/* Calculated price */
export const postReservationCalculatePriceOk = typed.object({
  dockPricePerHour: typed.string.optional,
  hours: typed.number.optional,
  dockPrice: typed.string.optional,
  tax: typed.string.optional,
  totalPrice: typed.string.optional,
  charterFee: typed.string.maybe,
  bookingFee: typed.string.maybe,
  refundPrice: typed.number.optional,
});
export type PostReservationCalculatePriceDone = {
  status: "ok";
  answer: typed.Get<typeof postReservationCalculatePriceOk>;
};

/* Invalid input */
export const postReservationCalculatePriceBadRequest = typed.nul;

/* Unprocessable entity */
export const postReservationCalculatePriceUnprocessableEntity = typed.nul;
export type PostReservationCalculatePriceFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postReservationCalculatePriceBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postReservationCalculatePriceUnprocessableEntity>;
  }
  | GenericErrors;
export const postReservationCalculatePrice = createEffect<
  PostReservationCalculatePrice,
  PostReservationCalculatePriceDone,
  PostReservationCalculatePriceFail
>({
  async handler({ body }) {
    const name = "postReservationCalculatePrice.body";
    const response = await requestFx({
      path: "/api/reservation-calculate-price",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", postReservationCalculatePriceOk],
      400: ["bad_request", postReservationCalculatePriceBadRequest],
      422: [
        "unprocessable_entity",
        postReservationCalculatePriceUnprocessableEntity,
      ],
    });
  },
});
//#endregion postReservationCalculatePrice

/* --- */
//#region getReservationSchedule
export type GetReservationSchedule = {
  query: {
    dockId: number;
    boatId?: number;
    loa?: number;
    hours: number;
    monthStartDate: string;
    reservationId: number;
  };
};

/* Get schedule */
export const getReservationScheduleOk = typed.array(
  typed.array(
    typed.object({
      from: typed.string.optional,
      to: typed.string.optional,
    })
  )
);
export type GetReservationScheduleDone = {
  status: "ok";
  answer: typed.Get<typeof getReservationScheduleOk>;
};

/* Invalid input */
export const getReservationScheduleBadRequest = typed.nul;

/* Unprocessable entity */
export const getReservationScheduleUnprocessableEntity = typed.nul;
export type GetReservationScheduleFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof getReservationScheduleBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof getReservationScheduleUnprocessableEntity>;
  }
  | GenericErrors;
export const getReservationSchedule = createEffect<
  GetReservationSchedule,
  GetReservationScheduleDone,
  GetReservationScheduleFail
>({
  async handler({ query }) {
    const name = "getReservationSchedule.body";
    const response = await requestFx({
      path: "/api/reservation-schedule",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getReservationScheduleOk],
      400: ["bad_request", getReservationScheduleBadRequest],
      422: ["unprocessable_entity", getReservationScheduleUnprocessableEntity],
    });
  },
});
//#endregion getReservationSchedule

/* --- */
//#region getReservationCollection
export type GetReservationCollection = {
  query?: {
    /* The collection page number */
    page?: number;

    /* The number of items per page */
    itemsPerPage?: number;

    /* Enable or disable pagination */
    pagination?: boolean;

    /* Search by keyword on id | client or manager fullname, email, phone | boat name, manufacturer, model | dock name */
    keyword?: string;
    author?: string;
    "timeFrom[before]"?: string;
    "timeFrom[strictly_before]"?: string;
    "timeFrom[after]"?: string;
    "timeFrom[strictly_after]"?: string;
    "timeTo[before]"?: string;
    "timeTo[strictly_before]"?: string;
    "timeTo[after]"?: string;
    "timeTo[strictly_after]"?: string;
    "paidAt[before]"?: string;
    "paidAt[strictly_before]"?: string;
    "paidAt[after]"?: string;
    "paidAt[strictly_after]"?: string;
    client?: string;
    "client[]"?: string[];
    dock?: string;
    "dock[]"?: string[];
    "order[id]"?: "asc" | "desc";
    "order[client.fullName]"?: "asc" | "desc";
    "order[timeFrom]"?: "asc" | "desc";
    "order[dock.name]"?: "asc" | "desc";
    "order[price.totalPrice]"?: "asc" | "desc";
    "order[paidAt]"?: "asc" | "desc";
    "order[author]"?: "asc" | "desc";
  };
};

/* Reservation collection */
export const getReservationCollectionOk = typed.object({
  "hydra:member": typed.array(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      dock: typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        id: typed.number.optional,
        company: typed.object({
          "@context": typed.union(
            typed.string,
            typed.object({
              "@vocab": typed.string,
              hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
            })
          ).optional,
          "@id": typed.string.optional,
          "@type": typed.string.optional,
          name: typed.string,
        }).optional,
        image: typed.object({
          "@context": typed.union(
            typed.string,
            typed.object({
              "@vocab": typed.string,
              hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
            })
          ).optional,
          "@id": typed.string.optional,
          "@type": typed.string.optional,
          url: typed.string.maybe,
        }),
        name: typed.string,
        address: typed.string,
        city: typed.string,
        state: typed.string,
        latitude: typed.string.maybe,
        longitude: typed.string.maybe,
        zipCode: typed.string,
        phone: typed.string,
        email: typed.string,
        operationalHours: typed.array(
          typed.object({
            "@context": typed.union(
              typed.string,
              typed.object({
                "@vocab": typed.string,
                hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
              })
            ).optional,
            "@id": typed.string.optional,
            "@type": typed.string.optional,

            /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
            dayOfWeek: typed.number,
            timeFrom: typed.string,
            timeTo: typed.string,
          })
        ).optional,
        restaurants: typed.array(
          typed.object({
            "@context": typed.union(
              typed.string,
              typed.object({
                "@vocab": typed.string,
                hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
              })
            ).optional,
            "@id": typed.string.optional,
            "@type": typed.string.optional,
            type: typed.string.maybe,
            name: typed.string,
            url: typed.string.maybe,
            distance: typed.number.maybe,
          })
        ).optional,
      }),
      boat: typed.union(
        typed.object({
          "@context": typed.union(
            typed.string,
            typed.object({
              "@vocab": typed.string,
              hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
            })
          ).optional,
          "@id": typed.string.optional,
          "@type": typed.string.optional,
          id: typed.number.optional,
          name: typed.string,
          manufacturer: typed.string,
          model: typed.string,
          year: typed.number,
        })
      ),
      loa: typed.number.optional,
      beam: typed.number.optional,
      client: typed.union(
        typed.object({
          "@context": typed.union(
            typed.string,
            typed.object({
              "@vocab": typed.string,
              hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
            })
          ).optional,
          "@id": typed.string.optional,
          "@type": typed.string.optional,
          id: typed.number.optional,
          email: typed.string,
          firstName: typed.string,
          lastName: typed.string,
          phone: typed.string,
        })
      ),
      manager: typed.union(
        typed.object({
          "@context": typed.union(
            typed.string,
            typed.object({
              "@vocab": typed.string,
              hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
            })
          ).optional,
          "@id": typed.string.optional,
          "@type": typed.string.optional,
          id: typed.number.optional,
          email: typed.string,
          firstName: typed.string,
          lastName: typed.string,
          phone: typed.string.maybe,
        })
      ),
      timeFrom: typed.string,
      timeTo: typed.string,
      reason: typed.string.maybe,
      reasonOther: typed.string.maybe,
      guestCount: typed.number.maybe,
      price: typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        dockPricePerHour: typed.string.optional,
        hours: typed.number.optional,
        dockPrice: typed.string.optional,
        tax: typed.string.optional,
        totalPrice: typed.string.optional,
        charterFee: typed.string.maybe,
        bookingFee: typed.string.maybe,
        refundPrice: typed.number.optional,
      }).optional,
      comment: typed.string.maybe,
      billing: typed.union(
        typed.object({
          "@context": typed.union(
            typed.string,
            typed.object({
              "@vocab": typed.string,
              hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
            })
          ).optional,
          "@id": typed.string.optional,
          "@type": typed.string.optional,
          firstName: typed.string,
          lastName: typed.string,
          email: typed.string,
          phone: typed.string,
          address1: typed.string,
          address2: typed.string.maybe,
          city: typed.string,
          state: typed.string,
          zipCode: typed.string,
        })
      ),
      paymentIntent: typed.string.maybe,
      customerId: typed.string.maybe,
      cardLast4: typed.string.maybe,
      status: typed.string.optional,
      isCharter: typed.boolean.optional,
      createdAt: typed.string.optional,
      updatedAt: typed.string.optional,
      paidAt: typed.string.maybe,
    })
  ),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional,
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(
      typed.object({
        "@type": typed.string.optional,
        variable: typed.string.optional,
        property: typed.string.maybe,
        required: typed.boolean.optional,
      })
    ).optional,
  }).optional,
});
export type GetReservationCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getReservationCollectionOk>;
};
export type GetReservationCollectionFail = GenericErrors;

/* Retrieves the collection of Reservation resources. */
export const getReservationCollection = createEffect<
  GetReservationCollection,
  GetReservationCollectionDone,
  GetReservationCollectionFail
>({
  async handler({ query }) {
    const name = "getReservationCollection.body";
    const response = await requestFx({
      path: "/api/reservations",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getReservationCollectionOk],
    });
  },
});
//#endregion getReservationCollection

/* --- */
//#region postReservationCollection
export type PostReservationCollection = {
  body: {
    dock: string;
    boat?: string | null;
    loa?: number;
    beam?: number;
    timeFrom: string;
    timeTo: string;
    reason?: string | null;
    reasonOther?: string | null;
    guestCount?: number | null;
    beforeTax?: string;
    comment?: string | null;
    isCharter?: boolean;
  };
};

/* Reservation resource created */
export const postReservationCollectionCreated = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  dock: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    company: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      name: typed.string,
    }).optional,
    image: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      url: typed.string.maybe,
    }),
    name: typed.string,
    address: typed.string,
    city: typed.string,
    state: typed.string,
    latitude: typed.string.maybe,
    longitude: typed.string.maybe,
    zipCode: typed.string,
    phone: typed.string,
    email: typed.string,
    operationalHours: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,

        /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
        dayOfWeek: typed.number,
        timeFrom: typed.string,
        timeTo: typed.string,
      })
    ).optional,
    restaurants: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        type: typed.string.maybe,
        name: typed.string,
        url: typed.string.maybe,
        distance: typed.number.maybe,
      })
    ).optional,
  }),
  boat: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      manufacturer: typed.string,
      model: typed.string,
      year: typed.number,
    })
  ),
  loa: typed.number.optional,
  beam: typed.number.optional,
  client: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string,
    })
  ),
  manager: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string.maybe,
    })
  ),
  timeFrom: typed.string,
  timeTo: typed.string,
  reason: typed.string.maybe,
  reasonOther: typed.string.maybe,
  guestCount: typed.number.maybe,
  price: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    dockPricePerHour: typed.string.optional,
    hours: typed.number.optional,
    dockPrice: typed.string.optional,
    tax: typed.string.optional,
    totalPrice: typed.string.optional,
    charterFee: typed.string.maybe,
    bookingFee: typed.string.maybe,
    refundPrice: typed.number.optional,
  }).optional,
  comment: typed.string.maybe,
  billing: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      firstName: typed.string,
      lastName: typed.string,
      email: typed.string,
      phone: typed.string,
      address1: typed.string,
      address2: typed.string.maybe,
      city: typed.string,
      state: typed.string,
      zipCode: typed.string,
    })
  ),
  paymentIntent: typed.string.maybe,
  customerId: typed.string.maybe,
  cardLast4: typed.string.maybe,
  status: typed.string.optional,
  isCharter: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
  paidAt: typed.string.maybe,
});
export type PostReservationCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postReservationCollectionCreated>;
};

/* Invalid input */
export const postReservationCollectionBadRequest = typed.nul;

/* Unprocessable entity */
export const postReservationCollectionUnprocessableEntity = typed.nul;
export type PostReservationCollectionFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postReservationCollectionBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postReservationCollectionUnprocessableEntity>;
  }
  | GenericErrors;

/* Creates a Reservation resource. */
export const postReservationCollection = createEffect<
  PostReservationCollection,
  PostReservationCollectionDone,
  PostReservationCollectionFail
>({
  async handler({ body }) {
    const name = "postReservationCollection.body";
    const response = await requestFx({
      path: "/api/reservations",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postReservationCollectionCreated],
      400: ["bad_request", postReservationCollectionBadRequest],
      422: [
        "unprocessable_entity",
        postReservationCollectionUnprocessableEntity,
      ],
    });
  },
});
//#endregion postReservationCollection

/* --- */
//#region getReservationItem
export type GetReservationItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Reservation resource */
export const getReservationItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  dock: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    company: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      name: typed.string,
    }).optional,
    image: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      url: typed.string.maybe,
    }),
    name: typed.string,
    address: typed.string,
    city: typed.string,
    state: typed.string,
    latitude: typed.string.maybe,
    longitude: typed.string.maybe,
    zipCode: typed.string,
    phone: typed.string,
    email: typed.string,
    operationalHours: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,

        /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
        dayOfWeek: typed.number,
        timeFrom: typed.string,
        timeTo: typed.string,
      })
    ).optional,
    restaurants: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        type: typed.string.maybe,
        name: typed.string,
        url: typed.string.maybe,
        distance: typed.number.maybe,
      })
    ).optional,
  }),
  boat: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      manufacturer: typed.string,
      model: typed.string,
      year: typed.number,
    })
  ),
  loa: typed.number.optional,
  beam: typed.number.optional,
  client: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string,
    })
  ),
  manager: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string.maybe,
    })
  ),
  timeFrom: typed.string,
  timeTo: typed.string,
  reason: typed.string.maybe,
  reasonOther: typed.string.maybe,
  guestCount: typed.number.maybe,
  price: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    dockPricePerHour: typed.string.optional,
    hours: typed.number.optional,
    dockPrice: typed.string.optional,
    tax: typed.string.optional,
    totalPrice: typed.string.optional,
    charterFee: typed.string.maybe,
    bookingFee: typed.string.maybe,
    refundPrice: typed.number.optional,
  }).optional,
  comment: typed.string.maybe,
  billing: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      firstName: typed.string,
      lastName: typed.string,
      email: typed.string,
      phone: typed.string,
      address1: typed.string,
      address2: typed.string.maybe,
      city: typed.string,
      state: typed.string,
      zipCode: typed.string,
    })
  ),
  paymentIntent: typed.string.maybe,
  customerId: typed.string.maybe,
  cardLast4: typed.string.maybe,
  status: typed.string.optional,
  isCharter: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
  paidAt: typed.string.maybe,
});
export type GetReservationItemDone = {
  status: "ok";
  answer: typed.Get<typeof getReservationItemOk>;
};

/* Resource not found */
export const getReservationItemNotFound = typed.nul;
export type GetReservationItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getReservationItemNotFound>;
  }
  | GenericErrors;

/* Retrieves a Reservation resource. */
export const getReservationItem = createEffect<
  GetReservationItem,
  GetReservationItemDone,
  GetReservationItemFail
>({
  async handler({ path }) {
    const name = "getReservationItem.body";
    const response = await requestFx({
      path: `/api/reservations/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getReservationItemOk],
      404: ["not_found", getReservationItemNotFound],
    });
  },
});
//#endregion getReservationItem

/* --- */
//#region putReservationItem
export type PutReservationItem = {
  body: {
    dock: string;
    boat: string;
    timeFrom: string;
    timeTo: string;
    comment?: string;
    isCharter: boolean;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Reservation resource updated */
export const putReservationItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  dock: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    company: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      name: typed.string,
    }).optional,
    image: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      url: typed.string.maybe,
    }),
    name: typed.string,
    address: typed.string,
    city: typed.string,
    state: typed.string,
    latitude: typed.string.maybe,
    longitude: typed.string.maybe,
    zipCode: typed.string,
    phone: typed.string,
    email: typed.string,
    operationalHours: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,

        /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
        dayOfWeek: typed.number,
        timeFrom: typed.string,
        timeTo: typed.string,
      })
    ).optional,
    restaurants: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        type: typed.string.maybe,
        name: typed.string,
        url: typed.string.maybe,
        distance: typed.number.maybe,
      })
    ).optional,
  }),
  boat: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      manufacturer: typed.string,
      model: typed.string,
      year: typed.number,
    })
  ),
  loa: typed.number.optional,
  beam: typed.number.optional,
  client: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string,
    })
  ),
  manager: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string.maybe,
    })
  ),
  timeFrom: typed.string,
  timeTo: typed.string,
  reason: typed.string.maybe,
  reasonOther: typed.string.maybe,
  guestCount: typed.number.maybe,
  price: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    dockPricePerHour: typed.string.optional,
    hours: typed.number.optional,
    dockPrice: typed.string.optional,
    tax: typed.string.optional,
    totalPrice: typed.string.optional,
    charterFee: typed.string.maybe,
    bookingFee: typed.string.maybe,
    refundPrice: typed.number.optional,
  }).optional,
  comment: typed.string.maybe,
  billing: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      firstName: typed.string,
      lastName: typed.string,
      email: typed.string,
      phone: typed.string,
      address1: typed.string,
      address2: typed.string.maybe,
      city: typed.string,
      state: typed.string,
      zipCode: typed.string,
    })
  ),
  paymentIntent: typed.string.maybe,
  customerId: typed.string.maybe,
  cardLast4: typed.string.maybe,
  status: typed.string.optional,
  isCharter: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
  paidAt: typed.string.maybe,
});
export type PutReservationItemDone = {
  status: "ok";
  answer: typed.Get<typeof putReservationItemOk>;
};

/* Invalid input */
export const putReservationItemBadRequest = typed.nul;

/* Resource not found */
export const putReservationItemNotFound = typed.nul;

/* Unprocessable entity */
export const putReservationItemUnprocessableEntity = typed.nul;
export type PutReservationItemFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof putReservationItemBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof putReservationItemNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof putReservationItemUnprocessableEntity>;
  }
  | GenericErrors;

/* Replaces the Reservation resource. */
export const putReservationItem = createEffect<
  PutReservationItem,
  PutReservationItemDone,
  PutReservationItemFail
>({
  async handler({ body, path }) {
    const name = "putReservationItem.body";
    const response = await requestFx({
      path: `/api/reservations/${path.id}`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putReservationItemOk],
      400: ["bad_request", putReservationItemBadRequest],
      404: ["not_found", putReservationItemNotFound],
      422: ["unprocessable_entity", putReservationItemUnprocessableEntity],
    });
  },
});
//#endregion putReservationItem

/* --- */
//#region updateAddonsReservationItem
export type UpdateAddonsReservationItem = {
  body: {
    isCharter: boolean;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Reservation resource updated */
export const updateAddonsReservationItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  dock: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    company: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      name: typed.string,
    }).optional,
    image: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      url: typed.string.maybe,
    }),
    name: typed.string,
    address: typed.string,
    city: typed.string,
    state: typed.string,
    latitude: typed.string.maybe,
    longitude: typed.string.maybe,
    zipCode: typed.string,
    phone: typed.string,
    email: typed.string,
    operationalHours: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,

        /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
        dayOfWeek: typed.number,
        timeFrom: typed.string,
        timeTo: typed.string,
      })
    ).optional,
    restaurants: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        type: typed.string.maybe,
        name: typed.string,
        url: typed.string.maybe,
        distance: typed.number.maybe,
      })
    ).optional,
  }),
  boat: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      manufacturer: typed.string,
      model: typed.string,
      year: typed.number,
    })
  ),
  loa: typed.number.optional,
  beam: typed.number.optional,
  client: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string,
    })
  ),
  manager: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string.maybe,
    })
  ),
  timeFrom: typed.string,
  timeTo: typed.string,
  reason: typed.string.maybe,
  reasonOther: typed.string.maybe,
  guestCount: typed.number.maybe,
  price: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    dockPricePerHour: typed.string.optional,
    hours: typed.number.optional,
    dockPrice: typed.string.optional,
    tax: typed.string.optional,
    totalPrice: typed.string.optional,
    charterFee: typed.string.maybe,
    bookingFee: typed.string.maybe,
    refundPrice: typed.number.optional,
  }).optional,
  comment: typed.string.maybe,
  billing: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      firstName: typed.string,
      lastName: typed.string,
      email: typed.string,
      phone: typed.string,
      address1: typed.string,
      address2: typed.string.maybe,
      city: typed.string,
      state: typed.string,
      zipCode: typed.string,
    })
  ),
  paymentIntent: typed.string.maybe,
  customerId: typed.string.maybe,
  cardLast4: typed.string.maybe,
  status: typed.string.optional,
  isCharter: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
  paidAt: typed.string.maybe,
});
export type UpdateAddonsReservationItemDone = {
  status: "ok";
  answer: typed.Get<typeof updateAddonsReservationItemOk>;
};

/* Invalid input */
export const updateAddonsReservationItemBadRequest = typed.nul;

/* Resource not found */
export const updateAddonsReservationItemNotFound = typed.nul;

/* Unprocessable entity */
export const updateAddonsReservationItemUnprocessableEntity = typed.nul;
export type UpdateAddonsReservationItemFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof updateAddonsReservationItemBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof updateAddonsReservationItemNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof updateAddonsReservationItemUnprocessableEntity>;
  }
  | GenericErrors;

/* Replaces the Reservation resource. */
export const updateAddonsReservationItem = createEffect<
  UpdateAddonsReservationItem,
  UpdateAddonsReservationItemDone,
  UpdateAddonsReservationItemFail
>({
  async handler({ body, path }) {
    const name = "updateAddonsReservationItem.body";
    const response = await requestFx({
      path: `/api/reservations/${path.id}/addons`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", updateAddonsReservationItemOk],
      400: ["bad_request", updateAddonsReservationItemBadRequest],
      404: ["not_found", updateAddonsReservationItemNotFound],
      422: [
        "unprocessable_entity",
        updateAddonsReservationItemUnprocessableEntity,
      ],
    });
  },
});
//#endregion updateAddonsReservationItem

/* --- */
//#region updateBillingReservationItem
export type UpdateBillingReservationItem = {
  body: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address1: string;
    address2?: string | null;
    city: string;
    state: string;
    zipCode: string;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Reservation resource updated */
export const updateBillingReservationItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  dock: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    company: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      name: typed.string,
    }).optional,
    image: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      url: typed.string.maybe,
    }),
    name: typed.string,
    address: typed.string,
    city: typed.string,
    state: typed.string,
    latitude: typed.string.maybe,
    longitude: typed.string.maybe,
    zipCode: typed.string,
    phone: typed.string,
    email: typed.string,
    operationalHours: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,

        /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
        dayOfWeek: typed.number,
        timeFrom: typed.string,
        timeTo: typed.string,
      })
    ).optional,
    restaurants: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        type: typed.string.maybe,
        name: typed.string,
        url: typed.string.maybe,
        distance: typed.number.maybe,
      })
    ).optional,
  }),
  boat: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      manufacturer: typed.string,
      model: typed.string,
      year: typed.number,
    })
  ),
  loa: typed.number.optional,
  beam: typed.number.optional,
  client: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string,
    })
  ),
  manager: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string.maybe,
    })
  ),
  timeFrom: typed.string,
  timeTo: typed.string,
  reason: typed.string.maybe,
  reasonOther: typed.string.maybe,
  guestCount: typed.number.maybe,
  price: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    dockPricePerHour: typed.string.optional,
    hours: typed.number.optional,
    dockPrice: typed.string.optional,
    tax: typed.string.optional,
    totalPrice: typed.string.optional,
    charterFee: typed.string.maybe,
    bookingFee: typed.string.maybe,
    refundPrice: typed.number.optional,
  }).optional,
  comment: typed.string.maybe,
  billing: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      firstName: typed.string,
      lastName: typed.string,
      email: typed.string,
      phone: typed.string,
      address1: typed.string,
      address2: typed.string.maybe,
      city: typed.string,
      state: typed.string,
      zipCode: typed.string,
    })
  ),
  paymentIntent: typed.string.maybe,
  customerId: typed.string.maybe,
  cardLast4: typed.string.maybe,
  status: typed.string.optional,
  isCharter: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
  paidAt: typed.string.maybe,
});
export type UpdateBillingReservationItemDone = {
  status: "ok";
  answer: typed.Get<typeof updateBillingReservationItemOk>;
};

/* Invalid input */
export const updateBillingReservationItemBadRequest = typed.nul;

/* Resource not found */
export const updateBillingReservationItemNotFound = typed.nul;

/* Unprocessable entity */
export const updateBillingReservationItemUnprocessableEntity = typed.nul;
export type UpdateBillingReservationItemFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof updateBillingReservationItemBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof updateBillingReservationItemNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof updateBillingReservationItemUnprocessableEntity>;
  }
  | GenericErrors;

/* Replaces the Reservation resource. */
export const updateBillingReservationItem = createEffect<
  UpdateBillingReservationItem,
  UpdateBillingReservationItemDone,
  UpdateBillingReservationItemFail
>({
  async handler({ body, path }) {
    const name = "updateBillingReservationItem.body";
    const response = await requestFx({
      path: `/api/reservations/${path.id}/billing`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", updateBillingReservationItemOk],
      400: ["bad_request", updateBillingReservationItemBadRequest],
      404: ["not_found", updateBillingReservationItemNotFound],
      422: [
        "unprocessable_entity",
        updateBillingReservationItemUnprocessableEntity,
      ],
    });
  },
});
//#endregion updateBillingReservationItem

/* --- */
//#region putByManagerReservationItem
export type PutByManagerReservationItem = {
  body: {
    dock: string;
    loa: number;
    beam: number;
    isCharter: boolean;
    timeFrom: string;
    timeTo: string;
    beforeTax: string;
    comment?: string;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Reservation resource updated */
export const putByManagerReservationItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  dock: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    company: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      name: typed.string,
    }).optional,
    image: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      url: typed.string.maybe,
    }),
    name: typed.string,
    address: typed.string,
    city: typed.string,
    state: typed.string,
    latitude: typed.string.maybe,
    longitude: typed.string.maybe,
    zipCode: typed.string,
    phone: typed.string,
    email: typed.string,
    operationalHours: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,

        /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
        dayOfWeek: typed.number,
        timeFrom: typed.string,
        timeTo: typed.string,
      })
    ).optional,
    restaurants: typed.array(
      typed.object({
        "@context": typed.union(
          typed.string,
          typed.object({
            "@vocab": typed.string,
            hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
          })
        ).optional,
        "@id": typed.string.optional,
        "@type": typed.string.optional,
        type: typed.string.maybe,
        name: typed.string,
        url: typed.string.maybe,
        distance: typed.number.maybe,
      })
    ).optional,
  }),
  boat: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
      manufacturer: typed.string,
      model: typed.string,
      year: typed.number,
    })
  ),
  loa: typed.number.optional,
  beam: typed.number.optional,
  client: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string,
    })
  ),
  manager: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string.maybe,
    })
  ),
  timeFrom: typed.string,
  timeTo: typed.string,
  reason: typed.string.maybe,
  reasonOther: typed.string.maybe,
  guestCount: typed.number.maybe,
  price: typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    dockPricePerHour: typed.string.optional,
    hours: typed.number.optional,
    dockPrice: typed.string.optional,
    tax: typed.string.optional,
    totalPrice: typed.string.optional,
    charterFee: typed.string.maybe,
    bookingFee: typed.string.maybe,
    refundPrice: typed.number.optional,
  }).optional,
  comment: typed.string.maybe,
  billing: typed.union(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      firstName: typed.string,
      lastName: typed.string,
      email: typed.string,
      phone: typed.string,
      address1: typed.string,
      address2: typed.string.maybe,
      city: typed.string,
      state: typed.string,
      zipCode: typed.string,
    })
  ),
  paymentIntent: typed.string.maybe,
  customerId: typed.string.maybe,
  cardLast4: typed.string.maybe,
  status: typed.string.optional,
  isCharter: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
  paidAt: typed.string.maybe,
});
export type PutByManagerReservationItemDone = {
  status: "ok";
  answer: typed.Get<typeof putByManagerReservationItemOk>;
};

/* Invalid input */
export const putByManagerReservationItemBadRequest = typed.nul;

/* Resource not found */
export const putByManagerReservationItemNotFound = typed.nul;

/* Unprocessable entity */
export const putByManagerReservationItemUnprocessableEntity = typed.nul;
export type PutByManagerReservationItemFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof putByManagerReservationItemBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof putByManagerReservationItemNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof putByManagerReservationItemUnprocessableEntity>;
  }
  | GenericErrors;

/* Replaces the Reservation resource. */
export const putByManagerReservationItem = createEffect<
  PutByManagerReservationItem,
  PutByManagerReservationItemDone,
  PutByManagerReservationItemFail
>({
  async handler({ body, path }) {
    const name = "putByManagerReservationItem.body";
    const response = await requestFx({
      path: `/api/reservations/${path.id}/by-manager`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putByManagerReservationItemOk],
      400: ["bad_request", putByManagerReservationItemBadRequest],
      404: ["not_found", putByManagerReservationItemNotFound],
      422: [
        "unprocessable_entity",
        putByManagerReservationItemUnprocessableEntity,
      ],
    });
  },
});
//#endregion putByManagerReservationItem

/* --- */
//#region postCancelReservation
export type PostCancelReservation = {
  body?: {
    refundAmount: string;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* Cancel reservation */
export const postCancelReservationOk = typed.object({
  id: typed.number.optional,
  dock: typed.object({
    id: typed.number.optional,
    company: typed.object({
      name: typed.string,
    }).optional,
    image: typed.object({
      url: typed.string.maybe,
    }),
    name: typed.string,
    address: typed.string,
    city: typed.string,
    state: typed.string,
    latitude: typed.string.maybe,
    longitude: typed.string.maybe,
    zipCode: typed.string,
    phone: typed.string,
    email: typed.string,
    operationalHours: typed.array(
      typed.object({
        /* Number from 1 to 7. Sunday - 1, Monday - 2, etc. */
        dayOfWeek: typed.number,
        timeFrom: typed.string,
        timeTo: typed.string,
      })
    ).optional,
    restaurants: typed.array(
      typed.object({
        type: typed.string.maybe,
        name: typed.string,
        url: typed.string.maybe,
        distance: typed.number.maybe,
      })
    ).optional,
  }),
  boat: typed.union(
    typed.object({
      id: typed.number.optional,
      name: typed.string,
      manufacturer: typed.string,
      model: typed.string,
      year: typed.number,
    })
  ),
  loa: typed.number.optional,
  beam: typed.number.optional,
  client: typed.union(
    typed.object({
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string,
    })
  ),
  manager: typed.union(
    typed.object({
      id: typed.number.optional,
      email: typed.string,
      firstName: typed.string,
      lastName: typed.string,
      phone: typed.string.maybe,
    })
  ),
  timeFrom: typed.string,
  timeTo: typed.string,
  reason: typed.string.maybe,
  reasonOther: typed.string.maybe,
  guestCount: typed.number.maybe,
  price: typed.object({
    dockPricePerHour: typed.string.optional,
    hours: typed.number.optional,
    dockPrice: typed.string.optional,
    tax: typed.string.optional,
    totalPrice: typed.string.optional,
    charterFee: typed.string.maybe,
    bookingFee: typed.string.maybe,
    refundPrice: typed.number.optional,
  }).optional,
  comment: typed.string.maybe,
  billing: typed.union(
    typed.object({
      firstName: typed.string,
      lastName: typed.string,
      email: typed.string,
      phone: typed.string,
      address1: typed.string,
      address2: typed.string.maybe,
      city: typed.string,
      state: typed.string,
      zipCode: typed.string,
    })
  ),
  paymentIntent: typed.string.maybe,
  customerId: typed.string.maybe,
  cardLast4: typed.string.maybe,
  status: typed.string.optional,
  isCharter: typed.boolean.optional,
  createdAt: typed.string.optional,
  updatedAt: typed.string.optional,
  paidAt: typed.string.maybe,
});
export type PostCancelReservationDone = {
  status: "ok";
  answer: typed.Get<typeof postCancelReservationOk>;
};

/* Resource not found */
export const postCancelReservationNotFound = typed.nul;

/* Unprocessable entity */
export const postCancelReservationUnprocessableEntity = typed.nul;
export type PostCancelReservationFail =
  | {
    status: "not_found";
    error: typed.Get<typeof postCancelReservationNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postCancelReservationUnprocessableEntity>;
  }
  | GenericErrors;
export const postCancelReservation = createEffect<
  PostCancelReservation,
  PostCancelReservationDone,
  PostCancelReservationFail
>({
  async handler({ body, path }) {
    const name = "postCancelReservation.body";
    const response = await requestFx({
      path: `/api/reservations/${path.id}/cancel`,
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", postCancelReservationOk],
      404: ["not_found", postCancelReservationNotFound],
      422: ["unprocessable_entity", postCancelReservationUnprocessableEntity],
    });
  },
});
//#endregion postCancelReservation

/* --- */
//#region getRestaurantTypeCollection
export type GetRestaurantTypeCollection = {
  query?: {
    /* The collection page number */
    page?: number;

    /* The number of items per page */
    itemsPerPage?: number;

    /* Enable or disable pagination */
    pagination?: boolean;
  };
};

/* RestaurantType collection */
export const getRestaurantTypeCollectionOk = typed.object({
  "hydra:member": typed.array(
    typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
    })
  ),
  "hydra:totalItems": typed.number.optional,
  "hydra:view": typed.object({
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    "hydra:first": typed.string.optional,
    "hydra:last": typed.string.optional,
    "hydra:previous": typed.string.optional,
    "hydra:next": typed.string.optional,
  }).optional,
  "hydra:search": typed.object({
    "@type": typed.string.optional,
    "hydra:template": typed.string.optional,
    "hydra:variableRepresentation": typed.string.optional,
    "hydra:mapping": typed.array(
      typed.object({
        "@type": typed.string.optional,
        variable: typed.string.optional,
        property: typed.string.maybe,
        required: typed.boolean.optional,
      })
    ).optional,
  }).optional,
});
export type GetRestaurantTypeCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getRestaurantTypeCollectionOk>;
};
export type GetRestaurantTypeCollectionFail = GenericErrors;

/* Retrieves the collection of RestaurantType resources. */
export const getRestaurantTypeCollection = createEffect<
  GetRestaurantTypeCollection,
  GetRestaurantTypeCollectionDone,
  GetRestaurantTypeCollectionFail
>({
  async handler({ query }) {
    const name = "getRestaurantTypeCollection.body";
    const response = await requestFx({
      path: "/api/restaurant-types",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getRestaurantTypeCollectionOk],
    });
  },
});
//#endregion getRestaurantTypeCollection

/* --- */
//#region postRestaurantTypeCollection
export type PostRestaurantTypeCollection = {
  body: {
    id?: number;
    name: string;
  };
};

/* RestaurantType resource created */
export const postRestaurantTypeCollectionCreated = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
});
export type PostRestaurantTypeCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postRestaurantTypeCollectionCreated>;
};

/* Invalid input */
export const postRestaurantTypeCollectionBadRequest = typed.nul;

/* Unprocessable entity */
export const postRestaurantTypeCollectionUnprocessableEntity = typed.nul;
export type PostRestaurantTypeCollectionFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postRestaurantTypeCollectionBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postRestaurantTypeCollectionUnprocessableEntity>;
  }
  | GenericErrors;

/* Creates a RestaurantType resource. */
export const postRestaurantTypeCollection = createEffect<
  PostRestaurantTypeCollection,
  PostRestaurantTypeCollectionDone,
  PostRestaurantTypeCollectionFail
>({
  async handler({ body }) {
    const name = "postRestaurantTypeCollection.body";
    const response = await requestFx({
      path: "/api/restaurant-types",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postRestaurantTypeCollectionCreated],
      400: ["bad_request", postRestaurantTypeCollectionBadRequest],
      422: [
        "unprocessable_entity",
        postRestaurantTypeCollectionUnprocessableEntity,
      ],
    });
  },
});
//#endregion postRestaurantTypeCollection

/* --- */
//#region getRestaurantTypeItem
export type GetRestaurantTypeItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* RestaurantType resource */
export const getRestaurantTypeItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
});
export type GetRestaurantTypeItemDone = {
  status: "ok";
  answer: typed.Get<typeof getRestaurantTypeItemOk>;
};

/* Resource not found */
export const getRestaurantTypeItemNotFound = typed.nul;
export type GetRestaurantTypeItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getRestaurantTypeItemNotFound>;
  }
  | GenericErrors;

/* Retrieves a RestaurantType resource. */
export const getRestaurantTypeItem = createEffect<
  GetRestaurantTypeItem,
  GetRestaurantTypeItemDone,
  GetRestaurantTypeItemFail
>({
  async handler({ path }) {
    const name = "getRestaurantTypeItem.body";
    const response = await requestFx({
      path: `/api/restaurant-types/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getRestaurantTypeItemOk],
      404: ["not_found", getRestaurantTypeItemNotFound],
    });
  },
});
//#endregion getRestaurantTypeItem

/* --- */
//#region putRestaurantTypeItem
export type PutRestaurantTypeItem = {
  body: {
    id?: number;
    name: string;
  };
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* RestaurantType resource updated */
export const putRestaurantTypeItemOk = typed.object({
  "@context": typed.union(
    typed.string,
    typed.object({
      "@vocab": typed.string,
      hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
    })
  ).optional,
  "@id": typed.string.optional,
  "@type": typed.string.optional,
  id: typed.number.optional,
  name: typed.string,
});
export type PutRestaurantTypeItemDone = {
  status: "ok";
  answer: typed.Get<typeof putRestaurantTypeItemOk>;
};

/* Invalid input */
export const putRestaurantTypeItemBadRequest = typed.nul;

/* Resource not found */
export const putRestaurantTypeItemNotFound = typed.nul;

/* Unprocessable entity */
export const putRestaurantTypeItemUnprocessableEntity = typed.nul;
export type PutRestaurantTypeItemFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof putRestaurantTypeItemBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof putRestaurantTypeItemNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof putRestaurantTypeItemUnprocessableEntity>;
  }
  | GenericErrors;

/* Replaces the RestaurantType resource. */
export const putRestaurantTypeItem = createEffect<
  PutRestaurantTypeItem,
  PutRestaurantTypeItemDone,
  PutRestaurantTypeItemFail
>({
  async handler({ body, path }) {
    const name = "putRestaurantTypeItem.body";
    const response = await requestFx({
      path: `/api/restaurant-types/${path.id}`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putRestaurantTypeItemOk],
      400: ["bad_request", putRestaurantTypeItemBadRequest],
      404: ["not_found", putRestaurantTypeItemNotFound],
      422: ["unprocessable_entity", putRestaurantTypeItemUnprocessableEntity],
    });
  },
});
//#endregion putRestaurantTypeItem

/* --- */
//#region deleteRestaurantTypeItem
export type DeleteRestaurantTypeItem = {
  path: {
    /* Resource identifier */
    id: string;
  };
};

/* RestaurantType resource deleted */
export const deleteRestaurantTypeItemNoContent = typed.nul;
export type DeleteRestaurantTypeItemDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteRestaurantTypeItemNoContent>;
};

/* Resource not found */
export const deleteRestaurantTypeItemNotFound = typed.nul;
export type DeleteRestaurantTypeItemFail =
  | {
    status: "not_found";
    error: typed.Get<typeof deleteRestaurantTypeItemNotFound>;
  }
  | GenericErrors;

/* Removes the RestaurantType resource. */
export const deleteRestaurantTypeItem = createEffect<
  DeleteRestaurantTypeItem,
  DeleteRestaurantTypeItemDone,
  DeleteRestaurantTypeItemFail
>({
  async handler({ path }) {
    const name = "deleteRestaurantTypeItem.body";
    const response = await requestFx({
      path: `/api/restaurant-types/${path.id}`,
      method: "DELETE",
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteRestaurantTypeItemNoContent],
      404: ["not_found", deleteRestaurantTypeItemNotFound],
    });
  },
});
//#endregion deleteRestaurantTypeItem

/* --- */
//#region postClientForgotPassword
export type PostClientForgotPassword = {
  body?: {
    email: string;
  };
};

/* Email with reset token sent */
export const postClientForgotPasswordNoContent = typed.nul;
export type PostClientForgotPasswordDone = {
  status: "no_content";
  answer: typed.Get<typeof postClientForgotPasswordNoContent>;
};

/* Invalid input */
export const postClientForgotPasswordBadRequest = typed.nul;

/* Unprocessable entity */
export const postClientForgotPasswordUnprocessableEntity = typed.nul;
export type PostClientForgotPasswordFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postClientForgotPasswordBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postClientForgotPasswordUnprocessableEntity>;
  }
  | GenericErrors;
export const postClientForgotPassword = createEffect<
  PostClientForgotPassword,
  PostClientForgotPasswordDone,
  PostClientForgotPasswordFail
>({
  async handler({ body }) {
    const name = "postClientForgotPassword.body";
    const response = await requestFx({
      path: "/client/forgot-password",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      204: ["no_content", postClientForgotPasswordNoContent],
      400: ["bad_request", postClientForgotPasswordBadRequest],
      422: [
        "unprocessable_entity",
        postClientForgotPasswordUnprocessableEntity,
      ],
    });
  },
});
//#endregion postClientForgotPassword

/* --- */
//#region getClientForgotPassword
export type GetClientForgotPassword = {
  path: {
    token: string;
  };
};

/* Valid token */
export const getClientForgotPasswordOk = typed.nul;
export type GetClientForgotPasswordDone = {
  status: "ok";
  answer: typed.Get<typeof getClientForgotPasswordOk>;
};

/* Incorrect or confirmed token */
export const getClientForgotPasswordNotFound = typed.nul;
export type GetClientForgotPasswordFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getClientForgotPasswordNotFound>;
  }
  | GenericErrors;
export const getClientForgotPassword = createEffect<
  GetClientForgotPassword,
  GetClientForgotPasswordDone,
  GetClientForgotPasswordFail
>({
  async handler({ path }) {
    const name = "getClientForgotPassword.body";
    const response = await requestFx({
      path: `/client/forgot-password/${path.token}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getClientForgotPasswordOk],
      404: ["not_found", getClientForgotPasswordNotFound],
    });
  },
});
//#endregion getClientForgotPassword

/* --- */
//#region postClientForgotPasswordToken
export type PostClientForgotPasswordToken = {
  body?: {
    password: string;
  };
  path: {
    token: string;
  };
};

/* Password changed */
export const postClientForgotPasswordTokenNoContent = typed.nul;
export type PostClientForgotPasswordTokenDone = {
  status: "no_content";
  answer: typed.Get<typeof postClientForgotPasswordTokenNoContent>;
};

/* Invalid input */
export const postClientForgotPasswordTokenBadRequest = typed.nul;

/* Incorrect or confirmed token */
export const postClientForgotPasswordTokenNotFound = typed.nul;

/* Unprocessable entity */
export const postClientForgotPasswordTokenUnprocessableEntity = typed.nul;
export type PostClientForgotPasswordTokenFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postClientForgotPasswordTokenBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof postClientForgotPasswordTokenNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postClientForgotPasswordTokenUnprocessableEntity>;
  }
  | GenericErrors;
export const postClientForgotPasswordToken = createEffect<
  PostClientForgotPasswordToken,
  PostClientForgotPasswordTokenDone,
  PostClientForgotPasswordTokenFail
>({
  async handler({ body, path }) {
    const name = "postClientForgotPasswordToken.body";
    const response = await requestFx({
      path: `/client/forgot-password/${path.token}`,
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      204: ["no_content", postClientForgotPasswordTokenNoContent],
      400: ["bad_request", postClientForgotPasswordTokenBadRequest],
      404: ["not_found", postClientForgotPasswordTokenNotFound],
      422: [
        "unprocessable_entity",
        postClientForgotPasswordTokenUnprocessableEntity,
      ],
    });
  },
});
//#endregion postClientForgotPasswordToken

/* --- */
//#region postClientLogin
export type PostClientLogin = {
  body?: {
    email?: string;
    password?: string;
  };
};

/* User authorized. Refresh page. */
export const postClientLoginNoContent = typed.nul;
export type PostClientLoginDone = {
  status: "no_content";
  answer: typed.Get<typeof postClientLoginNoContent>;
};
export type PostClientLoginFail = GenericErrors;
export const postClientLogin = createEffect<
  PostClientLogin,
  PostClientLoginDone,
  PostClientLoginFail
>({
  async handler({ body }) {
    const name = "postClientLogin.body";
    const response = await requestFx({
      path: "/client/login",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      204: ["no_content", postClientLoginNoContent],
    });
  },
});
//#endregion postClientLogin

/* --- */
//#region postClientLogout
export type PostClientLogout = {};

/* Session invalidated. Refresh page. */
export const postClientLogoutNoContent = typed.nul;
export type PostClientLogoutDone = {
  status: "no_content";
  answer: typed.Get<typeof postClientLogoutNoContent>;
};
export type PostClientLogoutFail = GenericErrors;
export const postClientLogout = createEffect<
  PostClientLogout,
  PostClientLogoutDone,
  PostClientLogoutFail
>({
  async handler() {
    const name = "postClientLogout.body";
    const response = await requestFx({
      path: "/client/logout",
      method: "POST",
    });
    return parseByStatus(name, response, {
      204: ["no_content", postClientLogoutNoContent],
    });
  },
});
//#endregion postClientLogout

/* --- */
//#region getClientMe
export type GetClientMe = {};

/* A JSON object containing current user information. */
export const getClientMeOk = typed.union(
  typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    email: typed.string,
    firstName: typed.string,
    createdAt: typed.string.optional,
    updatedAt: typed.string.maybe,
  }),
  typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    email: typed.string,
    company: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
    }).optional,
    firstName: typed.string,
    lastName: typed.string,
    phone: typed.string.maybe,
    createdAt: typed.string.optional,
    updatedAt: typed.string.maybe,
  }),
  typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    email: typed.string,
    firstName: typed.string,
    lastName: typed.string,
    phone: typed.string,
    createdAt: typed.string.optional,
    updatedAt: typed.string.maybe,
    spentMoney: typed.string.optional,
    address: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      address1: typed.string.maybe,
      address2: typed.string.maybe,
      city: typed.string.maybe,
      state: typed.string.maybe,
      zipCode: typed.string.maybe,
    }).optional,
    comment: typed.string.maybe,
  })
);
export type GetClientMeDone = {
  status: "ok";
  answer: typed.Get<typeof getClientMeOk>;
};
export type GetClientMeFail = GenericErrors;
export const getClientMe = createEffect<
  GetClientMe,
  GetClientMeDone,
  GetClientMeFail
>({
  async handler() {
    const name = "getClientMe.body";
    const response = await requestFx({
      path: "/client/me",
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getClientMeOk],
    });
  },
});
//#endregion getClientMe

/* --- */
//#region postClientRegister
export type PostClientRegister = {
  body?: {
    "@context?":
    | string
    | {
      "@vocab": string;
      hydra: "http://www.w3.org/ns/hydra/core#";
      [key: string]: unknown;
    };
    "@id?": string;
    "@type?": string;
    email: string;
    password?: string;
    firstName: string;
    lastName: string;
    phone: string;
    comment?: string | null;
  };
};

/* User registered and authorized. Refresh page. */
export const postClientRegisterNoContent = typed.nul;
export type PostClientRegisterDone = {
  status: "no_content";
  answer: typed.Get<typeof postClientRegisterNoContent>;
};

/* Invalid input */
export const postClientRegisterBadRequest = typed.nul;

/* Unprocessable entity */
export const postClientRegisterUnprocessableEntity = typed.nul;
export type PostClientRegisterFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postClientRegisterBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postClientRegisterUnprocessableEntity>;
  }
  | GenericErrors;
export const postClientRegister = createEffect<
  PostClientRegister,
  PostClientRegisterDone,
  PostClientRegisterFail
>({
  async handler({ body }) {
    const name = "postClientRegister.body";
    const response = await requestFx({
      path: "/client/register",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      204: ["no_content", postClientRegisterNoContent],
      400: ["bad_request", postClientRegisterBadRequest],
      422: ["unprocessable_entity", postClientRegisterUnprocessableEntity],
    });
  },
});
//#endregion postClientRegister

/* --- */
//#region postManagerForgotPassword
export type PostManagerForgotPassword = {
  body?: {
    email: string;
  };
};

/* Email with reset token sent */
export const postManagerForgotPasswordNoContent = typed.nul;
export type PostManagerForgotPasswordDone = {
  status: "no_content";
  answer: typed.Get<typeof postManagerForgotPasswordNoContent>;
};

/* Invalid input */
export const postManagerForgotPasswordBadRequest = typed.nul;

/* Unprocessable entity */
export const postManagerForgotPasswordUnprocessableEntity = typed.nul;
export type PostManagerForgotPasswordFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postManagerForgotPasswordBadRequest>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<typeof postManagerForgotPasswordUnprocessableEntity>;
  }
  | GenericErrors;
export const postManagerForgotPassword = createEffect<
  PostManagerForgotPassword,
  PostManagerForgotPasswordDone,
  PostManagerForgotPasswordFail
>({
  async handler({ body }) {
    const name = "postManagerForgotPassword.body";
    const response = await requestFx({
      path: "/manager/forgot-password",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      204: ["no_content", postManagerForgotPasswordNoContent],
      400: ["bad_request", postManagerForgotPasswordBadRequest],
      422: [
        "unprocessable_entity",
        postManagerForgotPasswordUnprocessableEntity,
      ],
    });
  },
});
//#endregion postManagerForgotPassword

/* --- */
//#region getManagerForgotPassword
export type GetManagerForgotPassword = {
  path: {
    token: string;
  };
};

/* Valid token */
export const getManagerForgotPasswordOk = typed.nul;
export type GetManagerForgotPasswordDone = {
  status: "ok";
  answer: typed.Get<typeof getManagerForgotPasswordOk>;
};

/* Incorrect or confirmed token */
export const getManagerForgotPasswordNotFound = typed.nul;
export type GetManagerForgotPasswordFail =
  | {
    status: "not_found";
    error: typed.Get<typeof getManagerForgotPasswordNotFound>;
  }
  | GenericErrors;
export const getManagerForgotPassword = createEffect<
  GetManagerForgotPassword,
  GetManagerForgotPasswordDone,
  GetManagerForgotPasswordFail
>({
  async handler({ path }) {
    const name = "getManagerForgotPassword.body";
    const response = await requestFx({
      path: `/manager/forgot-password/${path.token}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getManagerForgotPasswordOk],
      404: ["not_found", getManagerForgotPasswordNotFound],
    });
  },
});
//#endregion getManagerForgotPassword

/* --- */
//#region postManagerForgotPasswordToken
export type PostManagerForgotPasswordToken = {
  body?: {
    password: string;
  };
  path: {
    token: string;
  };
};

/* Password changed */
export const postManagerForgotPasswordTokenNoContent = typed.nul;
export type PostManagerForgotPasswordTokenDone = {
  status: "no_content";
  answer: typed.Get<typeof postManagerForgotPasswordTokenNoContent>;
};

/* Invalid input */
export const postManagerForgotPasswordTokenBadRequest = typed.nul;

/* Incorrect or confirmed token */
export const postManagerForgotPasswordTokenNotFound = typed.nul;

/* Unprocessable entity */
export const postManagerForgotPasswordTokenUnprocessableEntity = typed.nul;
export type PostManagerForgotPasswordTokenFail =
  | {
    status: "bad_request";
    error: typed.Get<typeof postManagerForgotPasswordTokenBadRequest>;
  }
  | {
    status: "not_found";
    error: typed.Get<typeof postManagerForgotPasswordTokenNotFound>;
  }
  | {
    status: "unprocessable_entity";
    error: typed.Get<
      typeof postManagerForgotPasswordTokenUnprocessableEntity
    >;
  }
  | GenericErrors;
export const postManagerForgotPasswordToken = createEffect<
  PostManagerForgotPasswordToken,
  PostManagerForgotPasswordTokenDone,
  PostManagerForgotPasswordTokenFail
>({
  async handler({ body, path }) {
    const name = "postManagerForgotPasswordToken.body";
    const response = await requestFx({
      path: `/manager/forgot-password/${path.token}`,
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      204: ["no_content", postManagerForgotPasswordTokenNoContent],
      400: ["bad_request", postManagerForgotPasswordTokenBadRequest],
      404: ["not_found", postManagerForgotPasswordTokenNotFound],
      422: [
        "unprocessable_entity",
        postManagerForgotPasswordTokenUnprocessableEntity,
      ],
    });
  },
});
//#endregion postManagerForgotPasswordToken

/* --- */
//#region postManagerLogin
export type PostManagerLogin = {
  body?: {
    email?: string;
    password?: string;
  };
};

/* User authorized. Refresh page. */
export const postManagerLoginNoContent = typed.nul;
export type PostManagerLoginDone = {
  status: "no_content";
  answer: typed.Get<typeof postManagerLoginNoContent>;
};
export type PostManagerLoginFail = GenericErrors;
export const postManagerLogin = createEffect<
  PostManagerLogin,
  PostManagerLoginDone,
  PostManagerLoginFail
>({
  async handler({ body }) {
    const name = "postManagerLogin.body";
    const response = await requestFx({
      path: "/manager/login",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      204: ["no_content", postManagerLoginNoContent],
    });
  },
});
//#endregion postManagerLogin

/* --- */
//#region postManagerLogout
export type PostManagerLogout = {};

/* Session invalidated. Refresh page. */
export const postManagerLogoutNoContent = typed.nul;
export type PostManagerLogoutDone = {
  status: "no_content";
  answer: typed.Get<typeof postManagerLogoutNoContent>;
};
export type PostManagerLogoutFail = GenericErrors;
export const postManagerLogout = createEffect<
  PostManagerLogout,
  PostManagerLogoutDone,
  PostManagerLogoutFail
>({
  async handler() {
    const name = "postManagerLogout.body";
    const response = await requestFx({
      path: "/manager/logout",
      method: "POST",
    });
    return parseByStatus(name, response, {
      204: ["no_content", postManagerLogoutNoContent],
    });
  },
});
//#endregion postManagerLogout

/* --- */
//#region getManagerMe
export type GetManagerMe = {};

/* A JSON object containing current user information. */
export const getManagerMeOk = typed.union(
  typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    email: typed.string,
    firstName: typed.string,
    createdAt: typed.string.optional,
    updatedAt: typed.string.maybe,
  }),
  typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    email: typed.string,
    company: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      id: typed.number.optional,
      name: typed.string,
    }).optional,
    firstName: typed.string,
    lastName: typed.string,
    phone: typed.string.maybe,
    createdAt: typed.string.optional,
    updatedAt: typed.string.maybe,
  }),
  typed.object({
    "@context": typed.union(
      typed.string,
      typed.object({
        "@vocab": typed.string,
        hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
      })
    ).optional,
    "@id": typed.string.optional,
    "@type": typed.string.optional,
    id: typed.number.optional,
    email: typed.string,
    firstName: typed.string,
    lastName: typed.string,
    phone: typed.string,
    createdAt: typed.string.optional,
    updatedAt: typed.string.maybe,
    spentMoney: typed.string.optional,
    address: typed.object({
      "@context": typed.union(
        typed.string,
        typed.object({
          "@vocab": typed.string,
          hydra: typed.union("http://www.w3.org/ns/hydra/core#"),
        })
      ).optional,
      "@id": typed.string.optional,
      "@type": typed.string.optional,
      address1: typed.string.maybe,
      address2: typed.string.maybe,
      city: typed.string.maybe,
      state: typed.string.maybe,
      zipCode: typed.string.maybe,
    }).optional,
    comment: typed.string.maybe,
  })
);
export type GetManagerMeDone = {
  status: "ok";
  answer: typed.Get<typeof getManagerMeOk>;
};
export type GetManagerMeFail = GenericErrors;
export const getManagerMe = createEffect<
  GetManagerMe,
  GetManagerMeDone,
  GetManagerMeFail
>({
  async handler() {
    const name = "getManagerMe.body";
    const response = await requestFx({
      path: "/manager/me",
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getManagerMeOk],
    });
  },
});
//#endregion getManagerMe
